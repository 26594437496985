import React from 'react'

const keyboardButtonModeClasses = [
    'k_cn k_hic', // "charNormal hiddenCaps",
    'k_cs k_hisc', // "charShift hiddenShiftCaps",
    'k_ca', // "charAlt",
    'k_csa', // "charShiftAlt",
    'k_cc k_hic', // "charCaps hiddenCaps",
    'k_csc k_hisc' // "charShiftCaps hiddenShiftCaps"
]

const KeyboardButtonMode = React.memo(({ charArray, id }) => (
    <>
        {charArray && charArray.length && charArray[id].ch ? (
            <span className={`${keyboardButtonModeClasses[id]}${charArray[id].dk ? ' deadKey' : ''}`}>
                {charArray[id].ch}
            </span>
        ) : null}
    </>
))

KeyboardButtonMode.displayName = 'KeyboardButtonMode'

const KeyboardButton = React.memo(({ buttonId, charArray }) => (
    <div id={`kb_b${buttonId}`} className='k_b'>
        {Number.isInteger(buttonId) && (
            <div>
                <KeyboardButtonMode charArray={charArray} id={0} />
                <KeyboardButtonMode charArray={charArray} id={1} />
                <KeyboardButtonMode charArray={charArray} id={2} />
                <KeyboardButtonMode charArray={charArray} id={3} />
                <KeyboardButtonMode charArray={charArray} id={4} />
                <KeyboardButtonMode charArray={charArray} id={5} />
            </div>
        )}
        {!Number.isInteger(buttonId) && (
            <div>
                <span className='title'>{buttonId}</span>
            </div>
        )}
    </div>
))
KeyboardButton.displayName = 'KeyboardButton'

export default KeyboardButton
