import { useCallback, useEffect, useState } from 'react'
import { isIOS } from 'react-device-detect'
import { useTextboxContext } from '../../../Context/TextboxContext.js'

const useClipboard = ({ copyElementRef }) => {
    const [TextboxState] = useTextboxContext()

    const [showCopy, setShowCopy] = useState(true)

    useEffect(() => {
        isIOS && setShowCopy(false)
    }, [])

    const selectText = useCallback(() => {
        const copyElm = copyElementRef.current
        let range, selection
        if (document.body.createTextRange) {
            range = document.body.createTextRange()
            range.moveToElementText(copyElm)
            range.select()
        } else if (window.getSelection) {
            selection = window.getSelection()
            range = document.createRange()
            range.selectNodeContents(copyElm)
            selection.removeAllRanges()
            selection.addRange(range)
        }
    }, [copyElementRef])

    const handleCopy = useCallback(() => {
        if (isIOS) return
        let copySupport = document.queryCommandSupported('copy')

        if (copySupport) {
            selectText()
            try {
                if (document.execCommand('copy')) window.getSelection().removeAllRanges()
            } catch (e) {
                copySupport = false
            }
        }

        if (!copySupport) {
            // TODO: add condition for IE only (ActiveXObject supported only in IE)
            // and make this work on IE with the flash swf
            let hasFlash = false
            try {
                hasFlash = Boolean(new window.ActiveXObject('ShockwaveFlash.ShockwaveFlash'))
            } catch (exception) {
                console.log(exception)
                hasFlash = typeof navigator.mimeTypes['application/x-shockwave-flash'] !== 'undefined'
            }
            if (hasFlash) {
                import('zeroclipboard').then((ZeroClipboard) => {
                    ZeroClipboard.config({ swfPath: 'static/js/ZeroClipboard.swf' })
                    var client = new ZeroClipboard(copyElementRef.current)

                    client.on('ready', function () {
                        // alert( "ZeroClipboard SWF is ready!" );

                        client.on('copy', function (event) {
                            var clipboard = event.clipboardData
                            clipboard.setData('text/plain', TextboxState.textbox1)
                            // clipboard.setData( "text/html", "<b>Copy me!</b>" );
                        })

                        // client.on('aftercopy', function (event) {
                        //     // `this` === `client`
                        //     // `event.target` === the element that was clicked
                        //     // alert("Copied text to clipboard: " + event.data["text/plain"] );
                        // })
                    })
                })
            }
        }
    }, [TextboxState.textbox1, copyElementRef, selectText])

    return [showCopy, handleCopy]
}

export default useClipboard
