import { Contexter } from './Contexter'

const initialState = {
    // t13n:
    t13n: false,
    stash: false,
    showEnglishKeyboard: false,
    rtl: false,
    returnPosition: { top: 0 },
    textToCheck: '',
    // textToInsert: '',
    chosenIndex: 0,
    results: [],
    done: false
}

const t13nSetChoice = (state, index) => ({
    ...state,
    chosenIndex: Math.min(Math.max(index, 0), state.results.length - 1)
})

const reducer = (state, action) => {
    switch (action.type) {
        case 'activate-tansliteration':
            return action.payload ? { ...state, t13n: true } : { ...initialState, rtl: state.rtl }
        case 'set-rtl':
            return { ...state, rtl: action.payload }
        case 'update-return-position':
            return { ...state, returnPosition: action.payload }
        case 'add-char':
            return { ...state, textToCheck: state.textToCheck + action.payload }
        case 'clear':
            return { ...state, textToCheck: '', done: false, chosenIndex: 0 }
        case 'backspace':
            return { ...state, textToCheck: state.textToCheck.slice(0, -1) }
        case 'set-results':
            return { ...state, results: action.payload }
        case 'set-choice':
            return t13nSetChoice(state, action.payload)
        case 'choice-left':
            return t13nSetChoice(state, state.chosenIndex + (state.rtl * 2 - 1)) // if rtl 1 else -1
        case 'choice-right':
            return t13nSetChoice(state, state.chosenIndex - (state.rtl * 2 - 1))
        // case 'insert-text':
        //     return { ...state, textToInsert: action.payload }
        case 'done':
            return { ...state, done: true }
        case 'stash':
            return !state.stash ? { ...state, stash: state.t13n, t13n: false, textToCheck: '' } : state
        case 'unstash':
            return state.stash ? { ...state, stash: false, t13n: state.stash } : state
        // case 'english-keyboard':
        //          return action.payload === state.showEnglishKeyboard ? state : {...state, showEnglishKeyboard: action.payload }
        default:
            return state
    }
}

const { Provider, useStateDispatch } = Contexter('T13n', [reducer], initialState)

export { useStateDispatch as useT13nContext }
export { Provider as T13nProvider }
