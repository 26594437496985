import { isEdge, isEdgeChromium, isIE, isIOS, isSafari } from 'react-device-detect'
import { DocumentSelection } from './extensions/documentselection.js'
// import EM from './extensions/eventmanager.js'
import { gluePath, isFunction, isString } from './extensions/helpers.js'
import { local as storeLocal } from '../../../Config/storage'
import keymaps from './keymaps.json'

const { __doParse, addLayout } = require('./vk_joint')

const getClosest = (elem, selector) => elem.closest(selector)

const EM_NEW = {
    getKeyCode: (e) => {
        switch (e.keyCode) {
            case 189:
                return 109
            case 187:
                return 61 // InternetExplorer
            case 107:
                return 61 // Firefox3
            case 186:
                return 59
            default:
                return e.keyCode
        }
    }
}

String.fromCharCodeExt = (code) => {
    if (code < 0x10000) {
        return String.fromCharCode(code)
    }
    return (
        String.fromCharCode(((code - 0x10000) >> 10) | 0xd800) +
        String.fromCharCode(((code - 0x10000) & 0x3ff) | 0xdc00)
    )
}

const adjustKeymap = (keymap) => {
    let map = keymaps[keymap].split('').map((c) => c.charCodeAt(0))
    /*
     *  add control keys
     */
    map.splice(14, 0, 8, 9)
    map.splice(28, 0, 13, 20)
    map.splice(41, 0, 16)
    map.splice(52, 0, 16, 46, 17, 18, 32, 18, 17)
    /*
     *  convert keymap array to the object, to have better typing speed
     */
    let tk = map
    map = []
    for (let z = 0, kL = tk.length; z < kL; z++) {
        map[tk[z]] = z
    }
    return map
}

// eslint-disable-next-line
const VirtualKeyboard = new (function () {
    const self = this

    /**
     *  Path to the keyboard install root
     *
     *  @type String
     *  @scope private
     */
    const basePath = '' // findPath('vk_loader.js');  // NOTE: gate2home additions
    /**
     *  Regexp to test a char against to prove it is a dead key
     *
     *  @type RegExp
     *  @scope private
     */
    // eslint-disable-next-line
    const DK_REG = /\x03/
    /**
     *  Some configurable stuff
     *
     *  @type Object
     *  @scope private
     */
    const options = {
        layout: null,
        skin: 'winxp'
    }
    /**
     *  ID prefix
     *
     *  @type String
     *  @scope private
     */
    const idPrefix = 'kb_b'
    /**
     *  This flag is used to enable or disable keyboard animation
     *  This is very useful in the secure environments, like password input. Controlled by the CSS class on the field
     *
     *  @see cssClasses
     *  @type Boolean
     *  @scope private
     */
    let animate = true
    /**
     *  This flag is used to check if keyboard is availble for operations (i.e. it does not wait for resource loading)
     *
     *  @type Boolean
     *  @scope private
     */
    const enabled = true

    /**
     *  Prefixes for the keys
     *
     *  @type Object
     *  @scope private
     */
    const KEY = {
        SHIFT: 'shift',
        ALT: 'alt',
        CTRL: 'ctrl',
        CAPS: 'caps'
    }
    /**
     *  Current keyboard mapping
     *
     *  @type Array
     *  @scope private
     */
    let keymap

    /**
     *  List of the available mappings
     *
     *  @type Object
     *  @scope private
     */

    /**
     *  Keyboard mode, bitmap
     *
     *
     *
     *
     *  @type Number
     *  @scope private
     */
    let mode = 0

    const VK_NORMAL = 0
    const VK_SHIFT = 1
    const VK_ALT = 2
    const VK_CTRL = 4
    const VK_CAPS = 8
    const VK_CTRL_CAPS = VK_CTRL | VK_CAPS
    const VK_CTRL_SHIFT = VK_CTRL | VK_SHIFT
    const VK_ALT_CAPS = VK_ALT | VK_CAPS
    const VK_ALT_CTRL = VK_ALT | VK_CTRL
    const VK_ALT_CTRL_CAPS = VK_ALT | VK_CTRL | VK_CAPS
    const VK_ALT_SHIFT = VK_ALT | VK_SHIFT
    const VK_SHIFT_ALT_CTRL = VK_SHIFT | VK_ALT | VK_CTRL
    const VK_SHIFT_CAPS = VK_SHIFT | VK_CAPS
    const VK_ALL = VK_SHIFT | VK_ALT | VK_CTRL | VK_CAPS
    /**
     *  CSS classes will be used to style buttons
     *
     *  @type Object
     *  @scope private
     */
    const cssClasses = {
        buttonUp: 'k_b', // 'kbButton'
        buttonDown: 'k_bd', // 'kbButtonDown'//
        buttonHover: 'k_bh', // 'kbButtonHover'//
        hoverShift: 'k_hs', // 'hoverShift'//
        hoverAlt: 'k_ha', // 'hoverAlt'//
        modeAlt: 'k_ma', // 'modeAlt'//
        modeAltCaps: 'k_mac', /// 'modeAltCaps'//none
        modeCaps: 'k_mc', // 'modeCaps'//
        modeNormal: 'k_mn', // 'modeNormal'//
        modeShift: 'k_ms', // 'modeShift'//
        modeShiftAlt: 'k_msa', // 'modeShiftAlt'//
        modeShiftAltCaps: 'k_msac', // 'modeShiftAltCaps//
        modeShiftCaps: 'k_msc', // 'modeShiftCaps'//
        charNormal: 'k_cn', // 'charNormal'//
        charShift: 'k_cs', // 'charShift'//
        charAlt: 'k_ca', // 'charAlt'//
        charShiftAlt: 'k_csa', // 'charShiftAlt'//
        charCaps: 'k_cc', // charCaps'//
        charShiftCaps: 'k_csc', // 'charShiftCaps'//
        hiddenAlt: 'k_hia', // 'hiddenAlt'//
        hiddenCaps: 'k_hic', // 'hiddenCaps'//
        hiddenShift: 'k_his', // 'hiddenShift' //
        hiddenShiftCaps: 'k_hisc', // ''hiddenShiftCaps'//
        deadkey: 'deadKey',
        noanim: 'VK_no_animate'
    }

    const ca = []
    ca[VK_NORMAL] = cssClasses.modeNormal
    ca[VK_SHIFT] = cssClasses.modeShift
    ca[VK_ALT_CTRL] = cssClasses.modeAlt
    ca[VK_SHIFT_ALT_CTRL] = cssClasses.modeShiftAlt
    ca[VK_CAPS] = cssClasses.modeCaps
    ca[VK_SHIFT_CAPS] = cssClasses.modeShiftCaps
    // these ones are the subject to change
    ca[VK_ALT] = cssClasses.modeAlt
    ca[VK_CTRL] = cssClasses.modeAlt
    ca[VK_ALT_SHIFT] = cssClasses.modeShift
    ca[VK_CTRL_SHIFT] = cssClasses.modeShift
    ca[VK_ALT_CAPS] = cssClasses.modeCaps
    ca[VK_CTRL_CAPS] = cssClasses.modeCaps
    // below ones are not used and should
    ca[VK_ALT_CTRL_CAPS] = cssClasses.modeShiftAltCaps
    ca[VK_ALL] = cssClasses.modeShiftAltCaps

    /**
     *  current layout
     *
     *  @type Object
     *  @scope public
     */
    let lang = null
    /**
     *  Available layouts
     *
     *  Structure:
     *   [ <char1>, <charN>]
     *  with the additional properties:
     *   .name : {String} layout name to find it using switchLayout
     *   .dk   : {String} list of the active dead keys, matches and replacements
     *   .cbk  : {Function} custom input transformations
     *               OR
     *           {Object} { 'activate' : optional activation (on layout select) callback
     *                      'charProcessor' : required input transformation callback, receiving 3 parameters:
     *                       - current input buffer (selection)
     *                       - processed char
     *                       - keyboard mode object, containing fields 'shift', 'alt', 'ctrl' and 'caps' where true means this modifier active
     *                    }
     *   .rtl  : right-to-left or left-to-right input flag
     *   .keys : multi-dimensional array of the key mapping
     *
     *  Where <char> is the array of the chars ['<normal>','<shift>','<alt>','<shift_alt>','<caps>','<shift_caps>']
     *
     *  @type Array
     *  @scope private
     */
    const layout = []
    /**
     *  Name-to-ID map
     *
     *  @type Object
     *  @scope private
     */
    layout.hash = {}
    /**
     *  Available layout codes
     *
     *  @type Array
     *  @scope private
     */
    layout.codes = {}
    /**
     *  Filter on the layout codes
     *
     *  @type Array
     *  @scope private
     */
    layout.codeFilter = null
    /**
     *  Generated layout options
     *
     *  @type Array
     *  @scope private
     */
    layout.options = null

    /**
     *  Shortcuts to the nodes
     *
     *  @type Object
     *  @scope private
     */
    const nodes = {
        keyboard: null, // Keyboard container @type HTMLDivElement
        desk: null, // Keyboard desk @type HTMLDivElement
        //  ,progressbar : null  // Progressbar @type HTMLDivElement
        langbox: null, // Language selector @type HTMLSelectElement
        attachedInput: null // Field, keyboard attached to
    }
    /**
     *  Key code to be inserted on the keypress
     *
     *  @type Number
     *  @scope private
     */
    let newKeyCode = null

    self.addLayout = (l) => {
        addLayout(l, layout)
    }
    /**
     *  Set current layout
     *
     *  @param {String} code layout name
     *  @return {Boolean} change state
     *  @scope public
     */
    self.switchLayout = (code) => {
        let res = true // (!lang || code !== lang.toString());

        //   if (res) {
        /*
         *  trying to regenerate options list
         */
        // __buildOptionsList()

        // if (!Object.prototype.hasOwnProperty.call(layout.options, code)) return false
        //   __setProgress(10);

        /*
         *  hide IME on layout switch
         */
        self.IME && self.IME.hide()

        /*
         *  touch the dropdown box
         */
        // if (nodes.langbox.options[layout.options[code]]) nodes.langbox.options[layout.options[code]].selected = true

        lang = layout.hash[code]

        // if (!lang) return false

        res = !!lang

        if (res) {
            /*
             *  trying to load resources before switching layout
             */
            if (lang.requires) {
                const arr = lang.requires.map((path) => gluePath(basePath, '/layouts/', path))
                const loading = lang.toString()

                // NOTE: gate2home changes
                const importLayout = (arr, runscript) => {
                    const next = arr.shift()
                    if (next) {
                        import('.' + next).then((module) => {
                            module.default(VirtualKeyboard)
                            runscript(arr)
                        })
                    }
                }
                const runscript = () => {
                    if (lang.toString() === loading) __layoutLoadMonitor.apply(self, [arr.length, true])
                    if (arr.length) importLayout(arr, runscript)
                }
                importLayout(arr, runscript)
                // NOTE: end gate2home changes
            } else {
                __layoutLoadMonitor(null, true)
            }
        } else {
            __layoutLoadMonitor(null, false)
        }
        //       } else {
        //           res = lang && code === lang.toString();
        // //          window.console.out = "Please wait, keyboard is not available";
        //       }
        return res
    }

    /**
     *  Return the list of the available layouts
     *
     *  @return {Array}
     *  @scope public
     */
    self.getLayouts = () => {
        // console.log('layoutt', layout)
        // const lts = []
        // for (let i = 0, lL = layout.length; i < lL; i++) {
        //     lts[lts.length] = [layout[i].domain, layout[i].code, layout[i].name]
        // }
        return layout
    }

    self.setLayouts = (hash) => {
        // console.log('layoutt', layout)
        // const lts = []
        // for (let i = 0, lL = layout.length; i < lL; i++) {
        //     lts[lts.length] = [layout[i].domain, layout[i].code, layout[i].name]
        // }
        layout.hash = hash
    }

    // ---------------------------------------------------------------------------
    // GLOBAL EVENT HANDLERS
    // ---------------------------------------------------------------------------
    /**
     *  Do the key clicks, caught from both virtual and real keyboards
     *
     *  @param {HTMLInputElement} key on the virtual keyboard
     *  @param {EventTarget} evt optional event object, to be used to re-map the keyCode
     *  @scope private
     */
    self.keyClicker = (key, evt, emoji = null) => {
        let chr = ''
        const ret = false

        // NOTE: gate2home addition
        // if (
        //     typeof nodes.attachedInput !== 'undefined' &&
        //     nodes.attachedInput &&
        //     nodes.attachedInput.nodeName !== 'TEXTAREA'
        // ) {
        //     const selNode = window.getSelection().anchorNode
        //     if (selNode instanceof HTMLElement || selNode === null) {
        //         if (selNode === null || !selNode.closest('.vkinput')) {
        //             // safari:
        //             const range = document.createRange()
        //             const el = nodes.attachedInput
        //             range.selectNodeContents(el)
        //             range.collapse()
        //             const sel = window.getSelection()
        //             sel.removeAllRanges()
        //             sel.addRange(range)
        //             return false
        //         }
        //     } else if (selNode !== null && selNode.parentNode !== null && !selNode.parentNode.closest('.vkinput')) {
        //         return false
        //     }
        // }
        // NOTE: end gate2home addition

        key = !emoji ? key.replace(idPrefix, '') : 'emoji'
        switch (key) {
            case KEY.CAPS:
            case KEY.SHIFT:
            case 'shift_left':
            case 'shift_right':
            case KEY.ALT:
            case 'alt_left':
            case 'alt_right':
                return true
            case 'backspace':
                /*
                 *  if layout has char processor and there's any selection, ask it for advice
                 */

                // Gate2Home addition:
                if (
                    self.isT13n &&
                    document.querySelector('#transliterate_editable') &&
                    document.querySelector('#transliterate_editable').getAttribute('data-res')
                ) {
                    self.dispatch({ type: 'backspace' })
                    break
                }
                // end Gate2Home addition:

                if (isFunction(lang.charProcessor) && DocumentSelection.getSelection(nodes.attachedInput).length) {
                    chr = '\x08'
                } else if (evt && evt.currentTarget === nodes.attachedInput) {
                    self.IME && self.IME.hide(true)
                    return true
                } else {
                    DocumentSelection.deleteAtCursor(nodes.attachedInput, false)
                    self.IME && self.IME.hide(true)
                }
                break
            case 'del':
                self.IME && self.IME.hide(true)
                if (evt) return true
                DocumentSelection.deleteAtCursor(nodes.attachedInput, true)
                break
            case 'space':
                chr = ' '
                break
            case 'tab':
                chr = '\t'
                break
            case 'enter':
                // NOTE: gate2home change
                chr = nodes.attachedInput && nodes.attachedInput.classList.contains('oneliner') ? '' : '\n'
                // NOTE: end gate2home addition
                break
            // case 'emoji':
            //     virtualprint = true
            //     chr = emoji.unified
            //     break
            default:
                if (lang && lang.keys && lang.keys[key]) chr = lang.keys[key][mode]
                break
        }

        // console.log(emoji)
        if (emoji) {
            DocumentSelection.insertAtCursor(nodes.attachedInput, emoji)
        }

        const chr_original = chr
        if (chr) {
            /*
             *  process current selection and new symbol with __charProcessor, it might update them
             */
            if (!(chr = __charProcessor(chr, DocumentSelection.getSelection(nodes.attachedInput)))) return ret

            /*
             *  try to create an event, then fallback to DocumentSelection, if something fails
             */
            let virtualprint = false
            /*
             * there are some global exceptions, when createEvent won't work properly
             *  - selection to set exists
             *  - multiple symbols should be inserted
             *  - multibyte unicode symbol should be inserted
             *  - rich text editor attached
             * note, we will skip the default behavior of the TAB
             */
            if (
                !chr[1] &&
                chr[0].length <= 1 &&
                chr[0].charCodeAt(0) <= 0x7fff &&
                nodes.attachedInput !== null &&
                !nodes.attachedInput.contentDocument
                //          && '\t' !== chr[0]
            ) {
                const ck = chr[0].charCodeAt(0)
                virtualprint = !__fireKbdEvent(ck, evt)
            } else {
                virtualprint = true
            }

            if (virtualprint) {
                DocumentSelection.insertAtCursor(nodes.attachedInput, chr[0])
                /*
                 *  select as much, as __charProcessor callback requested
                 */
                if (chr[1]) {
                    DocumentSelection.setRange(nodes.attachedInput, -chr[1], 0, true)
                }
            }
        }

        // NOTE: gate2home addition (and moved out from prev chr condition, so it will fire event also on non chars)
        let event
        if (document.createEvent) {
            event = document.createEvent('HTMLEvents')
            event.initEvent('input', true, true)
        } else {
            event = document.createEventObject()
            event.eventType = 'input'
        }
        event.eventName = 'input'

        if (nodes.attachedInput) {
            const pos = DocumentSelection.getStart(nodes.attachedInput)

            // // if(!(isIE || isEdge || isSafari) )
            if (chr_original !== ' ' && chr_original !== '\n') {
                // safari acting weird on normalizing it (missing presses)
                if (!isIE && !isIOS) {
                    nodes.attachedInput.normalize() // normalizing on not IE, else arabic letters not connected.
                }
                ;((isSafari && !isIOS) || (isEdge && !isEdgeChromium)) &&
                    DocumentSelection.setRange(nodes.attachedInput, pos, pos) // reset pos, else jumps to end on safari, (and ie9 misses last char)
            }

            //   (isSafari && !isMobile)
            if (document.createEvent) {
                nodes.attachedInput.dispatchEvent(event)
            } else {
                nodes.attachedInput.fireEvent(`on${event.eventType}`, event)
            }
        }
        // NOTE: end gate2home addition

        //

        return ret
    }
    /**
     *  Captures some keyboard events
     *
     *  @param {Event} keydown
     *  @scope protected
     */
    const _keydownHandler_ = (e) => {
        /*
         *  it's global event handler. do not process event, if keyboard is not ready to work
         */
        if (!self.isEnabled()) return
        /*
         *  record new keyboard mode
         */
        let newMode = mode
        /*
         *  differently process different events
         */
        const keyCode = EM_NEW.getKeyCode(e)
        // eslint-disable-next-line

        switch (e.type) {
            case 'keydown':
                self.IME && self.IME.keydownSwitch(keyCode, keymap, e)
                switch (keyCode) {
                    case 9: // don't touch the default behavior of Tab key,
                        break
                    case 8: // backspace
                    case 46: // del
                        if (keymap) {
                            var el = nodes.desk.childNodes[keymap[keyCode]]
                            /*
                             *  set the class only 1 time
                             */
                            if (animate && !e.repeat) el.classList.add(cssClasses.buttonDown)
                            if (!self.keyClicker(el.id, e)) e.preventDefault()
                        }
                        break
                    case 20: // caps lock
                        if (!e.repeat) {
                            newMode = newMode ^ VK_CAPS
                        }
                        break
                    case 27: // Esc
                        var start = DocumentSelection.getStart(nodes.attachedInput)
                        DocumentSelection.setRange(nodes.attachedInput, start, start)
                        return false
                    default:
                        if (!e.repeat) {
                            // newMode = newMode | e.shiftKey | (e.ctrlKey << 2) | (e.altKey << 1)
                            newMode = newMode | e.shiftKey | (e.altKey ? 6 : 0)
                        }
                        if (keymap && Object.prototype.hasOwnProperty.call(keymap, keyCode)) {
                            if ((!(e.altKey ^ e.ctrlKey) || e.altKey) && !e.metaKey) {
                                el = nodes.desk.childNodes[keymap[keyCode]]
                                if (animate && el) el.classList.add(cssClasses.buttonDown)
                                /*
                                 *  assign the key code to be inserted on the keypress
                                 */
                                if (el) newKeyCode = el.id
                            }
                            if (e.altKey) {
                                e.preventDefault()
                                /*
                                 *  this block is used to print a char when ctrl+alt pressed
                                 *  browsers does not invoke "kepress" in this case
                                 */
                                if (e.srcElement) {
                                    self.keyClicker(nodes.desk.childNodes[keymap[keyCode]].id, e)
                                    newKeyCode = ''
                                }
                            }
                        }
                        break
                }
                break
            case 'keyup':
                switch (keyCode) {
                    case 20:
                        break
                    default:
                        if (!e.repeat) {
                            // newMode = mode & (VK_ALL ^ (!e.shiftKey | (!e.ctrlKey << 2) | (!e.altKey << 1)))
                            newMode = mode & (VK_ALL ^ (!e.shiftKey | (!e.altKey ? 6 : 0)))
                        }
                        if (animate && keymap && Object.prototype.hasOwnProperty.call(keymap, keyCode)) {
                            typeof nodes.desk.childNodes[keymap[keyCode]] !== 'undefined' &&
                                nodes.desk.childNodes[keymap[keyCode]].classList.remove(cssClasses.buttonDown)
                        }
                }
                break
            case 'keypress':
                /*
                 *  flag is set only when virtual key passed to input target
                 */
                if (newKeyCode && !e.VK_bypass) {
                    if (!self.keyClicker(newKeyCode, e)) {
                        e.stopPropagation()
                        /*
                         *  keeps browsers away from running built-in event handlers
                         */
                        e.preventDefault()
                    }
                    /*
                     *  reset flag
                     */
                    newKeyCode = null
                }
                if (!mode ^ VK_ALT_CTRL && (e.altKey || e.ctrlKey)) {
                    self.IME && self.IME.hide()
                }
                if (
                    keyCode === 0 &&
                    !newKeyCode &&
                    !e.VK_bypass && // suppress dead keys from the keyboard driver
                    !e.ctrlKey &&
                    !e.altKey &&
                    !e.shiftKey // only when no special keys pressed, unblocking system shortcuts
                ) {
                    e.preventDefault()
                }
        }
        /*
         *  update layout state
         */
        if (newMode !== mode) {
            __updateControlKeys(newMode)
            __updateLayout()
        }
    }
    /**
     *  Handle clicks on the buttons, actually used with mouseup event
     *
     *  @param {Event} mouseup event
     *  @scope protected
     */

    self._btnClick_ = (e) => {
        /*
         *  either a pressed key or something new
         */
        e.preventDefault()
        let el = getClosest(e.srcElement || e.target, 'div')
        /*
         *  skip invalid nodes
         */
        if (!el || !el.parentNode.id.includes(idPrefix)) return
        el = el.parentNode

        if (
            ['caps', 'shift_left', 'shift_right', 'alt_left', 'alt_right', 'ctrl_left', 'ctrl_right'].includes(
                el.id.substring(idPrefix.length)
            )
        ) {
            return
        }

        if (el.classList.contains(cssClasses.buttonDown) || !animate) {
            self.keyClicker(el.id)
        }
        if (animate) {
            el.classList.remove(cssClasses.buttonDown)
        }

        // const newMode = mode & (VK_CAPS | e.shiftKey | (e.altKey << 1) | (e.ctrlKey << 2))
        const newMode = mode & (VK_CAPS | e.shiftKey | (e.ctrlKey || e.altKey ? 6 : 0))

        if (mode !== newMode) {
            __updateControlKeys(newMode)
            __updateLayout()
        }
        e.stopPropagation()
    }
    /**
     *  Handle mousedown event
     *
     *  Method is used to set 'pressed' button state and toggle shift, if needed
     *  Additionally, it is used by keyboard wrapper to forward keyboard events to the virtual keyboard
     *
     *  @param {Event} mousedown event
     *  @scope protected
     */

    self._btnMousedown_ = (e) => {
        /*
         *  either pressed key or something new
         */
        e.preventDefault()
        let el = getClosest(e.srcElement || e.target, 'div')
        /*
         *  skip invalid nodes
         */
        if (!el || !el.parentNode.id.includes(idPrefix)) return
        el = el.parentNode

        let newMode = mode

        const key = el.id.substring(idPrefix.length)
        switch (key) {
            case 'caps':
                newMode = newMode ^ VK_CAPS
                break
            case 'shift_left':
            case 'shift_right':
                /*
                 *  Shift is pressed in on both keyboard and virtual keyboard, return
                 */
                if (e.shiftKey) break
                newMode = newMode ^ VK_SHIFT
                break
            case 'alt_left':
            case 'alt_right':
            case 'ctrl_left':
            case 'ctrl_right':
                newMode = newMode ^ ((e.altKey << 1) ^ VK_ALT) ^ ((e.ctrlKey << 2) ^ VK_CTRL)
                break
            /*
             *  any real pressed key
             */
            default:
                if (animate) el.classList.add(cssClasses.buttonDown)
                break
        }

        if (mode !== newMode) {
            __updateControlKeys(newMode)
            __updateLayout()
        }

        e.preventDefault()
        e.stopPropagation()
    }

    self.switchMappingExternal = (value) => {
        storeLocal.setItem('vk_mapping', value)
        keymap = adjustKeymap(value)
    }

    /**********************************************************
     *  MOST COMMON METHODS
     **********************************************************/
    /**
     *  Used to attach keyboard output to specified input
     *
     *  @param {Null, HTMLInputElement,String} element to attach keyboard to
     *  @return {HTMLInputElement}
     *  @scope public
     */
    self.attachInput = (el) => {
        /*
         *  if null is supplied, don't change the target field
         */
        if (!el) return nodes.attachedInput
        if (isString(el)) el = document.getElementById(el)

        if (el === nodes.attachedInput || !el) return nodes.attachedInput

        /*
         *  perform initialization...
         */
        // if (!self.switchLayout(options.layout)) {
        //     /*
        //      *  if both tries fail, go away
        //      */
        //     throw new Error('No layouts available')
        // }

        /*
         *  detach everything
         */
        self.detachInput()

        /*
         *  set keyboard animation for the current field
         */
        animate = !el.classList.contains(cssClasses.noanim)
        /*
         *  for iframe target we track its HTML node
         */
        nodes.attachedInput = el

        if (el.contentWindow) {
            el = el.contentWindow.document.body.parentNode
        }
        el.focus()
        el.addEventListener('keydown', _keydownHandler_)
        el.addEventListener('keyup', _keydownHandler_)
        el.addEventListener('keypress', _keydownHandler_)
        self.IME && el.addEventListener('mousedown', self.IME.blurHandler, { passive: true })

        /*
         *  if current input target does stay in some other document, bind
         *  events processing to the current document to keep key translation working
         */
        const html = document.body.parentNode
        if (document.body.parentNode !== getClosest(el, 'html')) {
            html.addEventListener('keydown', _keydownHandler_)
            html.addEventListener('keyup', _keydownHandler_)
            html.addEventListener('keypress', _keydownHandler_)
        }

        return nodes.attachedInput
    }

    /**
     *  Detaches input from the virtual keyboard
     *
     *  @return detach state
     *  @scope private
     */
    self.detachInput = () => {
        if (!nodes.attachedInput) return false

        /*
         *  force IME hide on field switch
         */
        self.IME && self.IME.hide()
        /*
         *  remove every VK artifact from the old input
         */
        if (nodes.attachedInput) {
            let oe = nodes.attachedInput
            if (oe.contentWindow) {
                oe = oe.contentWindow.document.body.parentNode
            }
            oe.removeEventListener('keydown', _keydownHandler_)
            oe.removeEventListener('keypress', _keydownHandler_)
            oe.removeEventListener('keyup', _keydownHandler_)
            self.IME && oe.removeEventListener('mousedown', self.IME.blurHandler)

            /*
             *  remove any available event handlers from the current document
             *  don't check whether they exists, it's just doesn't matter
             */
            const html = document.body.parentNode
            html.removeEventListener('keydown', _keydownHandler_)
            html.removeEventListener('keyup', _keydownHandler_)
            html.removeEventListener('keypress', _keydownHandler_)
        }
        nodes.attachedInput = null
        return true
    }

    /**
     *  Returns the attached input node
     *
     *  @return {HTMLInputElement, Null}
     *  @scope public
     */
    self.getAttachedInput = () => nodes.attachedInput

    /**
     *  Returns true if keyboard is open and enabled (e.g. not in the middle of layout switching)
     *
     *  @return {Boolean}
     *  @scope public
     */
    self.isEnabled = () => enabled
    /**
     *  Flag of keyboard readyness
     *
     *  @return {Boolean} true when at least one layout is available
     *  @scope public
     */
    self.isReady = () => layout.length > 0
    // ---------------------------------------------------------------------------
    // PRIVATE METHODS
    // ---------------------------------------------------------------------------
    /**
     *  Tries to init and fire keyboard event to print new char
     *
     *  @param {Number} ck - char code to add to event
     *  @param {Event} evt - optional current event object
     *  @return event fire success flag
     *  @scope private
     */
    const __fireKbdEvent = (ck, evt) => {
        /*
         *  trying to create an event, borrowed from YAHOO.util.UserAction
         */
        if (isFunction(window.document.createEvent)) {
            const win = window
            try {
                evt = win.document.createEvent('KeyEvents')
                evt.initKeyEvent(
                    'keypress',
                    false,
                    true,
                    nodes.attachedInput.contentWindow,
                    false,
                    false,
                    false,
                    false,
                    0,
                    ck
                )
                if (evt.isTrusted === false) {
                    /*
                     * check for new FireFox bug-o-feature, since 12th generated keyboard events are not trusted
                     */
                    return false
                }
                evt.VK_bypass = true
                nodes.attachedInput.dispatchEvent(evt)
            } catch (ex) {
                /*
                 *  Safari implements
                 */
                try {
                    evt = win.document.createEvent('KeyboardEvents')
                    evt.initKeyEvent(
                        'keypress',
                        false,
                        true,
                        nodes.attachedInput.contentWindow,
                        false,
                        false,
                        false,
                        false,
                        ck,
                        0
                    )
                    evt.VK_bypass = true
                    nodes.attachedInput.dispatchEvent(evt)
                } catch (ex) {
                    return false
                }
            }
        } else {
            return false
        }
        return true
    }
    /**
     *  Monitors resource loading for {@link #switchLayout}. As of the first release only success route is supported
     *  This method is callback to ScriptQueue component
     *  At the same time this method is loaded from the switchLayout, if there's nothing to load
     *
     *  @param {String, Null} element - loaded item, null means that all is loaded
     *  @param {Boolean} success - item load state
     *  @scope private
     */
    const __layoutLoadMonitor = (element, success) => {
        if (!element) {
            if (success) {
                /*
                 *  everything is loaded, requires are not needed anymore
                 */
                delete lang.requires

                if (!lang.keys) {
                    __prepareLayout(lang)
                    lang.keyboardChars = __getKeyboardChars(lang.keys)
                }

                /*
                 *  overwrite layout
                 */
                //   nodes.desk.innerHTML = lang.html;
                nodes.setKebyoardChars(lang.keyboardChars)
                /*
                 *  set layout-dependent class names
                 */

                self.IME && (self.IME.css = lang.domain)

                /*
                 *  reset mode for the new layout
                 */
                mode = VK_NORMAL
                __updateLayout()

                /*
                 *  call IME activation method, if exists
                 */
                if (isFunction(lang.activate)) {
                    lang.activate()
                }

                /*
                 *  save layout name
                 */
                storeLocal.setItem('vk_layout', lang.id)

                options.layout = lang.id
                //   __setProgress(100);
            } else {
                let i = 6
                const timeout = setInterval(() => {
                    if (!--i) {
                        clearInterval(timeout)
                        __layoutLoadMonitor(null, true)
                    }
                }, 200)
            }
        } else if (success) {
            if (lang.requires) {
                //   __setProgress(Math.round(100/(lang.requires.length+1)));
                lang.requires.pop()
            }
        }
    }

    /**
     *  Prepares layout for typing
     *
     *  @param {Object} l layout object to process
     *  @scope private
     */
    const __prepareLayout = (l) => {
        /*
         *  convert layout in machine-aware form
         */
        const alpha = l.normal

        const shift = l.shift || {}
        const alt = l.alt || {}
        const shift_alt = l.shift_alt || {}
        const caps = l.caps || {}
        const shift_caps = l.shift_caps || {}
        const dk = l.dk
        const cbk = l.cbk
        let cs
        let ca
        let csa
        let cc
        let csc = null
        let ics
        let ica
        let icsa
        let icc
        let icsc = -1
        const lt = []
        let i = 0
        const aL = alpha.length
        for (i; i < aL; i++) {
            let char_normal = alpha[i] // normal chars
            let char_alt = null
            let char_caps = null
            let char_shift = null
            const chr = [char_normal]

            if (Object.prototype.hasOwnProperty.call(shift, i)) {
                cs = __doParse(shift[i])
                ics = i
            }
            if (ics > -1 && cs[i - ics]) {
                char_shift = cs[i - ics]
                chr[VK_SHIFT] = char_shift
            } else if (char_normal && char_normal !== (char_normal = char_normal.toUpperCase())) {
                chr[VK_SHIFT] = char_normal
                char_shift = char_normal
            }

            if (Object.prototype.hasOwnProperty.call(alt, i)) {
                ca = __doParse(alt[i])
                ica = i
            }
            if (ica > -1 && ca[i - ica]) {
                char_alt = ca[i - ica]
                chr[VK_ALT_CTRL] = char_alt
            }

            if (Object.prototype.hasOwnProperty.call(shift_alt, i)) {
                csa = __doParse(shift_alt[i])
                icsa = i
            }
            if (icsa > -1 && csa[i - icsa]) {
                chr[VK_SHIFT_ALT_CTRL] = csa[i - icsa]
            } else if (char_alt && char_alt !== (char_alt = char_alt.toUpperCase())) {
                chr[VK_SHIFT_ALT_CTRL] = char_alt
            }

            if (Object.prototype.hasOwnProperty.call(caps, i)) {
                cc = __doParse(caps[i])
                icc = i
            }
            if (icc > -1 && cc[i - icc]) {
                char_caps = cc[i - icc]
            }
            if (char_caps) {
                chr[VK_CAPS] = char_caps
            } else if (char_shift && char_shift.toUpperCase() !== char_shift.toLowerCase()) {
                chr[VK_CAPS] = char_shift
            } else if (char_normal) {
                chr[VK_CAPS] = char_normal.toUpperCase()
            }

            if (Object.prototype.hasOwnProperty.call(shift_caps, i)) {
                csc = __doParse(shift_caps[i])
                icsc = i
            }
            if (icsc > -1 && csc[i - icsc]) {
                chr[VK_SHIFT_CAPS] = csc[i - icsc]
            } else if (char_caps) {
                chr[VK_SHIFT_CAPS] = char_caps.toLowerCase()
            } else if (char_shift) {
                chr[VK_SHIFT_CAPS] = char_shift.toLowerCase()
            } else if (char_normal) {
                chr[VK_SHIFT_CAPS] = char_normal
            }

            lt[i] = chr
        }

        if (dk) {
            l.dk = {}
            for (i in dk) {
                if (Object.prototype.hasOwnProperty.call(dk, i)) {
                    let key = i
                    if (parseInt(i) && i > 9) {
                        key = String.fromCharCode(i)
                    }
                    /*
                     * last replace is used to simplify char processor
                     * deadkey found in the deadkey list will be substituted with itself on +1 position
                     */
                    l.dk[key] = __doParse(dk[i])
                        .join('')
                        .replace(key, key + key)
                }
            }
        }

        /*
         *  check for right-to-left languages
         */
        l.rtl = !!lt.join('').match(/[\u05b0-\u06ff]/)

        /*
         *  this CSS will be set on kbDesk
         */
        //      lt.domain = l.domain

        /*
         *  finalize things by calling loading callback, if exists
         */
        if (isFunction(cbk)) {
            l.charProcessor = cbk
        } else if (cbk) {
            l.activate = cbk.activate
            l.charProcessor = cbk.charProcessor
        }
        l.keys = lt

        delete l.normal
        delete l.shift
        delete l.alt
        delete l.shift_alt
        delete l.caps
        delete l.shift_caps
        delete l.cbk
    }
    /**
     *  Toggles layout mode (switch alternative key bindings)
     *
     *  @scope private
     */
    const __updateLayout = () => {
        // typeof nodes.desk !== "undefined" && nodes.desk.classList.remove(...ca); nodes.desk.classList.add(ca[mode]);
        typeof nodes.desk !== 'undefined' && (nodes.desk.className = `kbDesk ${ca[mode]}`)
    }
    /**
     *  Sets specified state on dual keys (like Alt, Ctrl)
     *
     *  @param {String} a1 key suffix to be checked
     *  @param {Number} a2 keyboard mode
     *  @scope private
     */
    const __updateControlKeys = (newMode) => {
        /*
         *  all changed bits will be raised
         */
        const changes = mode ^ newMode
        if (changes & VK_SHIFT) {
            __toggleControlKeysState(!!(newMode & VK_SHIFT), KEY.SHIFT)
        }
        if (changes & VK_ALT) {
            __toggleControlKeysState(!!(newMode & VK_ALT), KEY.ALT)
        }
        if (changes & VK_CTRL) {
            __toggleControlKeysState(!!(newMode & VK_CTRL), KEY.CTRL)
        }
        if (changes & VK_CAPS) {
            __toggleKeyState(!!(newMode & VK_CAPS), null, KEY.CAPS)
        }
        mode = newMode
    }
    /**
     *  Toggles control key state, designed for dual keys only
     *
     *  @param {Number, Boolean} state one of raised (0), down (1), hover (2)
     *  @param {String} prefix key name to be evaluated
     */
    const __toggleControlKeysState = (state, prefix) => {
        const s1 = document.getElementById(`${idPrefix + prefix}_left`)
        const s2 = document.getElementById(`${idPrefix + prefix}_right`)
        switch (0 + state) {
            case 0:
                if (typeof s2 !== 'undefined') {
                    s2.classList.remove(cssClasses.buttonDown)
                    s1.className = s2.className
                }
                break
            case 1:
                typeof nodes.desk !== 'undefined' &&
                    nodes.desk.classList.remove(cssClasses.hoverShift, cssClasses.hoverAlt)
                s2.classList.add(cssClasses.buttonDown)
                s1.className = s2.className
                break
            case 2:
                if (KEY.SHIFT === prefix && (mode & VK_SHIFT) ^ VK_SHIFT) {
                    nodes.desk.classList.add(cssClasses.hoverShift)
                } else if (KEY.ALT === prefix && mode ^ VK_ALT_CTRL) {
                    nodes.desk.classList.add(cssClasses.hoverAlt)
                }
                s2.classList.add(cssClasses.buttonHover)
                s1.className = s2.className
                break
            case 3:
                if (KEY.SHIFT === prefix) {
                    typeof nodes.desk !== 'undefined' && nodes.desk.classList.remove(cssClasses.hoverShift)
                } else if (KEY.ALT === prefix) {
                    typeof nodes.desk !== 'undefined' && nodes.desk.classList.remove(cssClasses.hoverAlt)
                }
                if (typeof s2 !== 'undefined') {
                    s2.classList.remove(cssClasses.buttonHover)
                    s1.className = s2.className
                }
                break
            default:
                break
        }
    }
    /**
     *  Toggles key state
     *
     *  @param {String} name key suffix
     *  @param {Number, Boolean} state one of raised (0), down (1), hover (2), out (3)
     *  @param {HTMLElement} element key element
     */
    const __toggleKeyState = (state, element, name) => {
        const s = element || document.getElementById(idPrefix + name)
        if (s) {
            switch (0 + state) {
                case 0:
                    s.classList.remove(cssClasses.buttonDown)
                    break
                case 1:
                    s.classList.add(cssClasses.buttonDown)
                    break
                case 2:
                    s.classList.add(cssClasses.buttonHover)
                    break
                case 3:
                    s.classList.remove(cssClasses.buttonHover)
                    break
                default:
                    break
            }
        }
    }
    /**
     *  Char processor
     *
     *  It does process input letter, possibly modifies it
     *
     *  @param {String} char letter to be processed
     *  @param {String} buf current keyboard buffer
     *  @return {Array} new char, flag keep buffer contents
     *  @scope private
     */
    const __charProcessor = (tchr, buf) => {
        let res = [tchr, 0]
        if (isFunction(lang.charProcessor)) {
            /*
             *  call user-supplied converter
             */
            const state = {
                shift: mode & VK_SHIFT,
                alt: mode & VK_ALT,
                ctrl: mode & VK_CTRL,
                caps: mode & VK_CAPS
            }
            res = lang.charProcessor(tchr, buf, state)
        } else if (tchr === '\x08') {
            res = ['', 0]
        } else if (lang.dk && buf.length <= 1) {
            /*
             *  process char in buffer first
             *  buffer size should be exactly 1 char to don't mess with the occasional selection
             */
            const isDk = DK_REG.test(tchr)
            tchr = tchr.replace(DK_REG, '')
            if (Object.prototype.hasOwnProperty.call(buf && lang.dk, buf)) {
                /*
                 *  dead key found, no more future processing
                 *  if new key is not an another deadkey
                 */
                res[1] = 0
                const dks = lang.dk[buf]
                const idx = dks.indexOf(tchr) + 1
                res[0] = idx ? dks.charAt(idx) : tchr
            } else if (Object.prototype.hasOwnProperty.call(isDk && lang.dk, tchr)) {
                /*
                 *  in all other cases, process char as usual
                 */
                res[1] = 1
                res[0] = tchr
            }
        }
        return res
    }
    /**
     * Keyboard layout builder
     *
     * @param {Array} lng keys to put on the keyboard
     * @return {String} serialized HTML
     * @scope private
     */
    const __getKeyboardChars = (lng) => {
        const kebyoardChars = {}
        for (let i = 0, aL = lng.length, chr; i < aL; i++) {
            chr = lng[i]
            kebyoardChars[i] = [
                __getCharHtmlForKey(lang, chr, VK_NORMAL),
                __getCharHtmlForKey(lang, chr, VK_SHIFT),
                __getCharHtmlForKey(lang, chr, VK_ALT_CTRL),
                __getCharHtmlForKey(lang, chr, VK_SHIFT_ALT_CTRL),
                __getCharHtmlForKey(lang, chr, VK_CAPS),
                __getCharHtmlForKey(lang, chr, VK_SHIFT_CAPS)
            ]
        }
        return kebyoardChars
    }
    /**
     *  Char html constructor
     *
     *  @param {Object} lyt layout object
     *  @param {String} chr char code
     *  @param {Number} mode char modifier
     *  @param {String} css optional additional class names
     *  @param {HTMLInputElement} i input field to test char length against
     *  @return {String} resulting html
     *  @scope private
     */
    const __getCharHtmlForKey = (lyt, chr, mode) => {
        let ch = chr[mode] || ''
        const dk =
            DK_REG.test(ch) && lyt.dk && Object.prototype.hasOwnProperty.call(lyt.dk, (ch = ch.replace(DK_REG, '')))

        // const dk = DK_REG.test(
        //     Object.prototype.hasOwnProperty.call(ch) && lyt.dk && lyt.dk,
        //     (ch = ch.replace(DK_REG, ''))
        // )
        return { ch, dk }
    }

    self.setSetKebyoardChars = (setKebyoardChars) => {
        nodes.setKebyoardChars = setKebyoardChars
    }

    self.setDispatch = (dispatch) => {
        self.dispatch = dispatch
        DocumentSelection.setDispatch(dispatch)
    }

    self.setIsT13n = (isT13n) => {
        self.isT13n = isT13n
        DocumentSelection.setIsT13n(isT13n)
    }

    self.init = (layout, virtualKeyboardDiv, setloadedIME) => {
        /*
         *  create keyboard UI
         */

        //load IME if needed
        const l = layout && layout.split(' ')[0]
        if (VirtualKeyboard.IME) setloadedIME(true)
        if (!VirtualKeyboard.IME && ['JP', 'CN', 'IPA'].includes(l)) {
            setloadedIME(true)
            VirtualKeyboard.loadIME(VirtualKeyboard)
        }

        nodes.desk = virtualKeyboardDiv.firstChild

        keymap = storeLocal.getItem('vk_mapping')
        if (!Object.prototype.hasOwnProperty.call(keymaps, keymap)) keymap = 'QWERTY Default'
        keymap = adjustKeymap(keymap)

        options.layout = layout || storeLocal.getItem('vk_layout')
        options.skin = ''
    }
})()

/**
 *  Container for the custom language IMEs, don't mess with the window object
 *
 *  @type {Object}
 */
VirtualKeyboard.Langs = {}
/**
 *  Simple IME thing to show input tips, supplied by the callback
 *
 *  Usage:
 *   - call VirtualKeyboard.IME.show(suggestionlist); to show the suggestions
 *   - call VirtualKeyboard.IME.hide(keep_selection); to hide IME toolbar and keep selectio if needed
 *
 *  @scope public
 */

VirtualKeyboard.IME = null
VirtualKeyboard.loadIME = async (VirtualKeyboard) => {
    VirtualKeyboard.IME = (await import('./VK_IME')).default(VirtualKeyboard)
}

VirtualKeyboard.Layout = () => {}

export default VirtualKeyboard

/* eslint-enable */
