// -----------------------------------------------------------------------------
//  Variable/property checks
// -----------------------------------------------------------------------------
/**
 *  Checks if property is undefined
 *
 *  @param {Object} prop value to check
 *  @return {Boolean} true if matched
 *  @scope public
 */
export function isUndefined(prop /* :Object */) /* :Boolean */ {
    return typeof prop === 'undefined'
}
/**
 *  Checks if property is function
 *
 *  @param {Object} prop value to check
 *  @return {Boolean} true if matched
 *  @scope public
 */
export function isFunction(prop /* :Object */) /* :Boolean */ {
    return typeof prop === 'function'
}
/**
 *  Checks if property is string
 *
 *  @param {Object} prop value to check
 *  @return {Boolean} true if matched
 *  @scope public
 */

export const isString = (str) => typeof str === 'string'

/**
 *  Checks if property is number
 *
 *  @param {Object} prop value to check
 *  @return {Boolean} true if matched
 *  @scope public
 */
export function isNumber(prop /* :Object */) /* :Boolean */ {
    return typeof prop === 'number'
}

/**
 *  Checks if property is array
 *
 *  @param {Object} prop value to check
 *  @return {Boolean} true if matched
 *  @scope public
 */
export function isArray(prop /* :Object */) /* :Boolean */ {
    return prop instanceof Array
}
/**
 *  Checks if property is regexp
 *
 *  @param {Object} prop value to check
 *  @return {Boolean} true if matched
 *  @scope public
 */
export function isRegExp(prop /* :Object */) /* :Boolean */ {
    return prop instanceof RegExp
}
/**
 *  Checks if property is a boolean value
 *
 *  @param {Object} prop value to check
 *  @return {Boolean} true if matched
 *  @scope public
 */
export function isBoolean(prop /* :Object */) /* :Boolean */ {
    return typeof prop === 'boolean'
}

export function isEmpty(prop /* :Object */) /* :Boolean */ {
    if (isBoolean(prop)) return false
    if (isRegExp(prop) && new RegExp('').toString() === prop.toString()) return true
    if (isString(prop) || isNumber(prop) || isFunction(prop)) return !prop
    if (Boolean(prop) && prop !== false) {
        for (const i in prop) if (Object.prototype.hasOwnProperty.call(prop, i)) return false
    }
    return true
}
// -----------------------------------------------------------------------------
//  File paths functions
// -----------------------------------------------------------------------------
/**
 *  used to glue path's
 *
 *  @param {String} number of strings
 *  @return {String} glued path
 *  @scope public
 */
/* eslint-disable */
export function gluePath(...args) /* :String */ {
    const aL = args.length
    let i = aL - 2
    let s = args[aL - 1]
    for (; i >= 0; i--) s = (!isString(args[i]) && !isNumber(args[i])) || isEmpty(args[i]) ? s : `${args[i]}\x00${s}`
    return s ? s.replace(/\/*\x00+\/*/g, '/') : ''
}
/* eslint-enable */
