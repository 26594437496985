/*
 *  $Id: documentselection.js 780 2011-06-22 13:20:20Z wingedfox $
 *  $HeadURL: https://svn.debugger.ru/repos/jslibs/BrowserExtensions/tags/BrowserExtensions.029/documentselection.js $
 *
 *  Class implements cross-browser work with text selection
 *
 *  @author Ilya Lebedev
 *  @author $Author: wingedfox $
 *  @modified $Date: 2011-06-22 17:20:20 +0400 (Wed, 22 Jun 2011) $
 *  @version $Rev: 780 $
 *  @license LGPL
 */
/*
 *  @class DocumentSelection
 */

import input_module from './input_module'
import div_module from './div_module'

// eslint-disable-next-line
export const DocumentSelection = new (function () {
    const self = this
    /*
     *  Stores hash of keys, applied to elements
     *
     *  @type Object
     *  @scope private
     */

    let currentmodule = null
    // ---------------------------------------------------------------------------
    //  PRIVATES
    // ---------------------------------------------------------------------------
    /**
     *  Calls specified method with the supplied params
     *  This is done to process only correct requests
     *
     *  @param {String} m method to call
     *  @param {Array} arg arguments of [target, param1, paramN]
     *  @return {Object} method call result or false, if any error happened
     *  @scope private
     */
    const callMethod = (m, arg) => {
        const el = arg[0]
        let module = ''

        if (!el || !el.tagName) return false
        switch (arg[0].tagName.toLowerCase()) {
            case 'input':
                if (
                    ['button', 'checkbox', 'hidden', 'image', 'radio', 'reset', 'submit'].includes(
                        (el.type || '').toLowerCase()
                    )
                )
                    return false
                break
            case 'textarea':
                module = 'input'
                break
            // case 'iframe':
            //     module = 'frame'
            //     arg[0] = el.contentWindow
            //     break
            // NOTE: gate2home addition
            case 'div':
                module = 'div'
                // arg[0] = el.contentWindow;
                break
            // NOTE: end gate2home addition
            default:
                return false
        }
        /*
         *  instantiate the module
         */

        // if (typeof modules[module] === 'function') currentmodule = new modules[module](keys)

        if (!currentmodule) {
            if (module == 'div') {
                currentmodule = new div_module()
            }
            if (module == 'input') {
                currentmodule = new input_module()
            }
            /*
             *  throw the exception, is method is not implemented
             */
            if (!currentmodule || !currentmodule[m])
                throw new Error(`Method '${m}' is not implemented for DocumentSelection '${module}' module.`)
        }
        return currentmodule[m].apply(self, arg)
    }

    // ---------------------------------------------------------------------------
    //  SETTERS
    // ---------------------------------------------------------------------------
    /**
     *  getSelectionRange wrapper/emulator
     *
     *  @param {HTMLElement}
     *  @param {Number} start position
     *  @param {Number} end position
     *  @param {Boolean} related indicates calculation of range relatively to current start point
     *  @return void
     *  @scope public
     */
    self.setRange = (el, start, end, related) => {
        /*
         *  set range on relative coordinates
         */
        if (related) {
            const st = self.getStart(el)
            end = st + end
            start = st + start
        }
        if (start < 0) start = 0
        if (end < start) end = start

        callMethod('setRange', [el, start, end])
    }

    self.setDispatch = (dispatch) => {
        self.dispatch = dispatch
    }

    self.setIsT13n = (isT13n) => {
        self.isT13n = isT13n
    }

    // ---------------------------------------------------------------------------
    //  GETTERS
    // ---------------------------------------------------------------------------
    /**
     *  Return contents of the current selection
     *
     *  @param {HTMLElement} el element to look position on
     *  @return {String}
     *  @scope public
     */
    self.getSelection = (el) => callMethod('getSelection', [el])
    /**
     *  getSelectionStart wrapper/emulator
     *  adapted version
     *
     *  @param {HTMLElement} el element to calculate end position for
     *  @return {Number} start position
     *  @scope public
     */
    self.getStart = (el) => callMethod('getPos', [el])[0]
    /*
     *  getSelectionEnd wrapper/emulator
     *  adapted version
     *
     *  @param {HTMLElement} el element to calculate end position for
     *  @return {Number} start position
     *  @scope public
     */
    self.getEnd = (el) => callMethod('getPos', [el])[0]
    /*
     *  Return cursor position for supplied field
     *
     *  @param {HTMLElement} element to get cursor position from
     *  @return {Number} position
     *  @scope public
     */
    self.getCursorPosition = (el) => self.getStart(el)

    // ---------------------------------------------------------------------------
    //  MISC FUNCTIONS
    // ---------------------------------------------------------------------------
    /*
     *  Insert text at cursor position
     *
     *  @param {HTMLElement} text field to insert text
     *  @param {String} text to insert
     *  @scope public
     */
    self.insertAtCursor = (el, val, keep) => {
        // Gate2Home addition:
        if (self.isT13n) {
            if (val === '\r' || val === '\n' || val === ' ') {
                // && document.querySelector("#transliterate_editable").innerHTML) {

                const transliterateEl = document.querySelector('#transliterate_editable')
                if (transliterateEl) {
                    const dataTranslitrationResult = transliterateEl.getAttribute('data-res')
                    val = dataTranslitrationResult ? `${dataTranslitrationResult} ` : val
                    transliterateEl.setAttribute('data-res', '')
                    transliterateEl.innerHTML = ''
                    self.dispatch({ type: 'clear' })
                }
            } else {
                self.dispatch({ type: 'add-char', payload: val })
                return
            }
        }
        if (!el) el = document.querySelector('.defaultvkinput')
        // end gate2home addition
        if (!keep) {
            callMethod('del', [el])
        }
        const pos = callMethod('ins', [el, val])
        return pos
    }
    // /*
    //  *  Wraps selection with start and end text
    //  *
    //  *  @param {HTMLElement} text field to insert text
    //  *  @param {String} start text at the beginnging of the selection
    //  *  @param {String} end text at the end of the selection
    //  *  @scope public
    //  */
    // self.wrapSelection = (el, start, end) => {
    //     const s = self.getCursorPosition(el)
    //     const e = self.getEnd(el)
    //     if (s === e) {
    //         self.insertAtCursor(el, start + end)
    //     } else {
    //         self.insertAtCursor(el, start, true)
    //         self.setRange(el, e + start.length, e + start.length)
    //         self.insertAtCursor(el, end, true)
    //     }
    // }

    /*
     *  Deletes char at cursor position
     *
     *  @param {HTMLElement} text field to delete text
     *  @param {Boolean} delete text before (backspace) or after (del) cursor
     *  @scope public
     */
    self.deleteAtCursor = (el, after) => {
        if (!self.getSelection(el)) {
            if (after) self.setRange(el, 0, 1, true)
            else self.setRange(el, -1, 0, true)
        }
        return self.deleteSelection(el)
    }
    /**
     *  Removes the selection, if available
     *
     *  @param {HTMLElement} el field to delete text from
     *  @scope public
     */
    self.deleteSelection = (el) => callMethod('del', [el])
    /**
     *  Method is used to caclulate pixel offsets for the selection in TextArea (other inputs are not tested yet)
     *
     *  @param {HTMLTextareaElement} el target to calculate offsets
     *  @return {Object} {x: horizontal offset, y: vertical offset, h: height offset}
     *  @scope public
     */
    self.getSelectionOffset = (el) => callMethod('getSelectionOffset', [el])
    /**
     *  Method is used to return cursor context within the current "word"
     *
     *  @param {HTMLTextareaElement} el target to calculate offsets
     *  @return {Array} 0 - part of word before the cursor, 1 - part after
     *  @scope public
     */
})()
