import { Contexter } from './Contexter'

const initialState = {
    active: true,
    showEnglishKeyboard: false,
    mobileLoadKeyboard: false,
    focusInputId: null,
    layout: ''
}

const reducer = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case 'update-value':
            return { ...state, [payload.key]: payload.value || '' }
        case 'set-active':
            return { ...state, active: payload }
        case 'english-keyboard':
            return payload === state.showEnglishKeyboard ? state : { ...state, showEnglishKeyboard: payload }
        case 'focus-input':
            return {
                ...state,
                focusInputId: payload
            }
        default:
            return state
    }
}

const { Provider, useStateDispatch } = Contexter('Keyboard', [reducer], initialState)

export { useStateDispatch as useKeyboardContext }
export { Provider as KeyboardProvider }
