import { useEffect } from 'react'
import { useKeyboardContext } from '../../Context/KeyboardContext'
import { local as storeLocal } from '../../Config/storage'
import VK from './VirtualKeyboard.full.3.7.2/virtualkeyboard.js'

const stringSimilarity = require('string-similarity')

const keymap_autofinder = {
    "`1234567890-=\\qwertyuiop[]asdfghjkl;'zxcvbnm,./": 'QWERTY Default',
    '\\1234567890-=]qwertzuiopç@asdfghjklë[yxcvbnm,./': 'QWERTZ Albanian',
    '²&é"\'(-è_çà)=*azertyuiop^$qsdfghjklmùwxcvbn,;:!': 'AZERTY French',

    '²&é"\'(§è!çà)-µazertyuiop^$qsdfghjklmùwxcvbn,;:=': 'AZERTY Belgian',
    "ˇ1234567890+´'qwertyuiopüõasdfghjklöäzxcvbnm,.-": 'QWERTY Estonian',
    "`1234567890-=\\;ςερτυθιοπ[]ασδφγηξκλ΄'ζχψωβνμ,./": 'QWERTY Greek (220)',
    '"1234567890*-,qwertyuıopğüasdfghjklşizxcvbnmöç.': 'QWERTY Turkish',
    "\\1234567890'ìùqwertyuiopè+asdfghjklòàzxcvbnm,.-": 'QWERTY Italian',
    '0123456789öüóűqwertzuiopőúasdfghjkléáyxcvbnm,.-': 'QWERTZ Hungarian',
    ';+ľščťžýáíé=´ňqwertzuiopúäasdfghjklô§yxcvbnm,.-': 'QWERTZ Slovak',
    '^1234567890ß´#qwertzuiopü+asdfghjklöäyxcvbnm,.-': 'QWERTZ German',
    // "`1234567890-=\\qwertyuiop[]asdfghjkl;'zxcvbnm,./":',WERTY Bulgarian', //why is it getting wrong keyQ?
    // "`1234567890-=\\qwertyuiop[]asdfghjkl;'zxcvbnm,./":'QGJRMV Latvian',
    ';+ěščřžýáíé=´¨qwertzuiopú)asdfghjklů§yxcvbnm,.-': 'QWERTZ Czech',
    "¸1234567890'+žqwertzuiopšđasdfghjklčćyxcvbnm,.-": 'QWERTZ Bosnian',
    "`1234567890-=#qwertyuiop[]asdfghjkl;'zxcvbnm,./": 'QWERTY UK',
    "º1234567890'¡çqwertyuiop`+asdfghjklñ´zxcvbnm,.- ": 'QWERTY Spanish',
    "|1234567890'¿}qwertyuiop´+asdfghjklñ{zxcvbnm,.-": 'QWERTY Spanish'
}

const orderedQwertyCodes = [
    'Backquote',
    'Digit1',
    'Digit2',
    'Digit3',
    'Digit4',
    'Digit5',
    'Digit6',
    'Digit7',
    'Digit8',
    'Digit9',
    'Digit0',
    'Minus',
    'Equal',
    'Backslash',
    'KeyQ',
    'KeyW',
    'KeyE',
    'KeyR',
    'KeyT',
    'KeyY',
    'KeyU',
    'KeyI',
    'KeyO',
    'KeyP',
    'BracketLeft',
    'BracketRight',
    'KeyA',
    'KeyS',
    'KeyD',
    'KeyF',
    'KeyG',
    'KeyH',
    'KeyJ',
    'KeyK',
    'KeyL',
    'Semicolon',
    'Quote',
    'KeyZ',
    'KeyX',
    'KeyC',
    'KeyV',
    'KeyB',
    'KeyN',
    'KeyM',
    'Comma',
    'Period',
    'Slash'
]

const useAutoMapping = (isDesktopMedia, setMapping) => {
    const [keyboardState, keyboardDispatch] = useKeyboardContext()

    useEffect(() => {
        const is_user_keymap = storeLocal.getItem('vk_mapping_is_user_set')
        // for changing mapping o every refresh, !keymap should be removed from condition (its persisted on localstorage)
        if (
            isDesktopMedia &&
            !is_user_keymap &&
            !keyboardState.selectedMapping &&
            navigator.keyboard !== undefined &&
            navigator.keyboard.getLayoutMap &&
            typeof navigator.keyboard.getLayoutMap == 'function'
        ) {
            navigator.keyboard.getLayoutMap().then((keyMap) => {
                let browserLayout = {}
                for (const element of keyMap.entries()) {
                    browserLayout[element[0]] = element[1]
                }
                const browserLayoutStr = orderedQwertyCodes.map((code) => browserLayout[code]).join('')

                // console.log(browserLayout, browserLayoutStr)
                let bestname = ''
                let bestscore = 0
                for (let keys_string in keymap_autofinder) {
                    let score = stringSimilarity.compareTwoStrings(keys_string, browserLayoutStr)
                    // console.log(keymap_autofinder[keys_string], keys_string)
                    // console.log(browserLayoutStr)
                    // console.log('=======')
                    if (score > bestscore) {
                        bestscore = score
                        bestname = keymap_autofinder[keys_string]
                    }
                }
                // window.dataLayer.push({
                //     event: 'mapping_event', // Not necessarily needed if this loads before GTM is initialized.
                //     mapping_type_var: 'auto',
                //     mapping_language_var: bestname
                // })
                if (bestname) {
                    setMapping(bestname)
                    VK.switchMappingExternal(bestname)
                    keyboardDispatch({ type: 'update-value', payload: { key: 'selectedMapping', value: bestname } })
                }
            })
        }
    }, [isDesktopMedia, keyboardDispatch, keyboardState.selectedMapping, setMapping])
}

export default useAutoMapping
