import { Contexter } from './Contexter'

const initialState = {
    showResults: false,
    showKeyboard: true
}

const reducer = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case 'show-results':
            return { ...state, showResults: payload }
        case 'show-keyboard':
            return { ...state, showKeyboard: payload }
        default:
            return state
    }
}

const { Provider, useStateDispatch } = Contexter('Search', [reducer], initialState)

export { useStateDispatch as useSearchContext }
export { Provider as SearchProvider }
