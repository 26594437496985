import React from 'react'

import Link from 'next/link'

import { keyboardLanguages, sitePath } from '../../StaticData/SiteData.js'

function KeyboardSectionLogoHeader({ kbLangKey, i18n }) {
    const native_title = keyboardLanguages.data[kbLangKey].title_native

    return (
        <div className={keyboardLanguages.data[kbLangKey].rtl ? 'kb-rtl' : ''}>
            <style jsx>{`
                .logo-with-bg {
                    width: 100%;
                }
                @media (max-width: 960px) {
                    .logo-with-bg {
                        margin-top: 48px;
                    }
                }
                .logo-gate2home-world {
                    padding-top: 37px;
                    padding-bottom: 8px;
                    height: 80px;
                }

                .logo-bubble {
                    position: relative;
                    width: 38px;
                    height: 37px;
                    top: -10px;
                    background-repeat: no-repeat;
                    background-image: url(${require('./logo_bubble_sma.png')});
                    display: inline-block;
                }
                h1 {
                    font-size: 18px;
                    text-shadow: 1px 1px 8px #1c3e5a;
                    margin-top: -11px;
                    color: white;
                    text-decoration: none;
                }
                .world_pattern_wrap::before {
                    content: ' ';
                    height: 67px;
                    background-image: linear-gradient(to bottom, transparent, #2964a3);
                    width: 300%;
                    position: absolute;
                    left: -100%;
                    top: 117px;
                }
                .world_pattern_wrap {
                    position: absolute;
                    width: 100%;
                    height: 184px;
                    top: 41px;
                    overflow: hidden;
                    z-index: -1;
                }
                .world_pattern_wrap::after {
                    content: ' ';
                    position: absolute;
                    top: -81px;
                    right: 0;
                    left: 0;
                    margin: auto;
                    border-radius: 50%;
                    height: 653px;
                    width: 734px;
                    background: radial-gradient(circle at 283px 241px, #3179c5, #021a33);
                    z-index: -6;
                }
                .world_pattern {
                    background-image: url(${require('./appback8.png')});
                    position: absolute;
                    width: 734px;
                    height: 216px;
                    opacity: 0.2;
                    background-size: 734px 216px;
                    background-repeat: no-repeat;
                    z-index: -1;
                    right: 0;
                    left: 0;
                    margin: auto;
                }
            `}</style>

            <div className='logo-with-bg'>
                <div className='world_pattern_wrap'>
                    <div className='world_pattern' />
                </div>
                <div className='logo-gate2home-world selectable'>
                    <Link
                        href={sitePath.keysToPath('', '', i18n)}
                        // style="text-decoration: none;"
                    >
                        <div
                            className='site_logo'
                            style={{
                                width: '342px',
                                height: '60px',
                                position: 'relative',
                                margin: '0px auto',
                                top: '-14px',
                                cursor: 'pointer'
                            }}
                        >
                            <img
                                src={require('./trans-logo-med2.png')}
                                alt='logo'
                                width='342'
                                height='60'
                                priority='true'
                            />
                        </div>
                    </Link>

                    <Link
                        href={sitePath.keysToPath(kbLangKey, '', i18n)}
                        // style="text-decoration: none;"
                    >
                        <h1>
                            {keyboardLanguages.keyToLocaleName(kbLangKey, i18n)}
                            {native_title && kbLangKey !== i18n.language ? ' (' + native_title + ')' : ''}
                        </h1>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default KeyboardSectionLogoHeader
