/**
 *  Module processing selection in the 'input' and 'textarea' fields
 *
 *  @param {Object} keys properties, registered for use in DS
 *  @scope protected
 */
function input_module() {
    const self = this

    /**
     *  Returns selection start or end position in absolute chars from the field start
     *
     *  @param {HTMLInputElement, HTMLTextareaElement} el input or textarea to get position from
     *  @return {Number} offset from the beginning
     *  @scope private
     */
    self.getPos = (el) => {
        const val = el.value
        let pos = [val.length, val.length]
        try {
            pos = [el.selectionStart, el.selectionEnd]
        } catch (e) {
            return
        }

        return pos
    }
    /**
     *  Removes the selection, if available
     *
     *  @param {HTMLElement} el field to delete text from
     *  @return {String} deleted substring
     *  @scope public
     */
    self.del = (el) => {
        let ret = ''
        const p = self.getPos(el)
        const s = p[0]
        const e = p[1]
        if (s !== e) {
            /*
             *  check for IE, because Opera uses \r\n sequence, but calculate positions correctly
             */
            ret = el.value.substring(s, e)
            el.value = el.value.substring(0, s) + el.value.substring(e, el.value.length)
            self.setRange(el, s, s)
        }

        return ret
    }
    /**
     *  Inserts text to the textarea
     *
     *  @param {HTMLElement} text field to insert text
     *  @param {String} text to insert
     *  @return {Number} new cursor position
     *  @scope public
     */
    self.ins = (el, val) => {
        let s = self.getPos(el)[0]
        const oLen = el.value.length
        el.value = el.value.substring(0, s) + val + el.value.substring(s, el.value.length)
        s += el.value.length - oLen
        self.setRange(el, s, s)
        return s
    }
    /**
     *  Return contents of the current selection
     *
     *  @param {HTMLElement} el element to look position on
     *  @param {Number} s start position
     *  @param {Number} e end position
     *  @return {String}
     *  @scope public
     */
    self.getSelection = (el) => {
        const p = self.getPos(el)
        const s = p[0]
        let e = p[1]
        /*
         *  w/o this check content might be duplicated on delete
         */
        if (e < s) e = s
        /*
         *  check for IE, because Opera does use \r\n sequence, but calculate positions correctly
         */
        return el.value.substring(s, e)
    }
    /**
     *  Sets the selection range
     *
     *  @param {HTMLElement}
     *  @param {Number} start position
     *  @param {Number} end position
     *  @return void
     *  @scope public
     */
    self.setRange = (el, start, end) => {
        /*
         *  for Mozilla
         */
        try {
            el.setSelectionRange(start, end)
        } catch (e) {
            return null
        }
    }
}

export default input_module
