const isString = (str) => typeof str === 'string'
function isArray(prop /* :Object */) /* :Boolean */ {
    return prop instanceof Array
}

/**
 *  Converts string of chars or array of char codes to the array of chars
 *
 *  @param {Array, String} s source to check&parse
 *  @return {Array}
 *  @scope private
 */
const __doParse = (s) =>
    isString(s)
        ? // eslint-disable-next-line no-control-regex
          s.match(/\x01.+?\x01|\x03.|[\ud800-\udbff][\udc00-\udfff]|./g).map((a) => a.replace(/[\x01\x02]/g, ''))
        : s.map((a) =>
              isArray(a)
                  ? a.map((s) => String.fromCharCodeExt(s)).join('')
                  : // eslint-disable-next-line no-control-regex
                    String.fromCharCodeExt(a).replace(/[\x01\x02]/g, '')
          )

/**
 *  Add layout to the list
 *
 *  @see #layout
 *  @param {Object} l layout description hash:
 *    { 'code'       : {String} layout code in form {language-COUNTRY}
 *     ,'name'       : {String} layout name
 *     ,'normal'     : {String,Array} keycodes without any modifiers, empty key should be set to 0x02 in array or char from this code in string
 *     ,'shift'      : {Object} optional shift keys, in form of <offset> : <codes>
 *     ,'alt'        : {Object} optional altgr keys, in form of <offset> : <codes>
 *     ,'shift_alt'  : {Object} optional shift+altgr keys, in form of <offset> : <codes>
 *     ,'caps'       : {Object} optional caps keys, in form of <offset> : <codes>
 *     ,'shift_caps' : {Object} optional shift+caps keys, in form of <offset> : <codes>
 *     ,'dk'   : {String} list of the active deadkeys in form of <char> : <deadkeys>
 *     ,'cbk' : {Function} char processing callback
 *                OR
 *              { 'activate' : {Function} optional activation callback (called from switchLayout)
 *               ,'charProcessor' : {Function} required char processing callback
 *              }
 *    }
 *
 *  <codes> is the array or string of the symbols. Codes might contain a ligatures in the form:
 *   string: substring of '0x01<char1><charN>0x01'
 *   array:  array of [<charCode1>,<charCodeN>]
 *
 *  <deadkeys> is the string or array of the matches and replacements
 *   string: string of '<match1><replacement1><matchN><replacementN>
 *   array: array of [<matchCharCode1><replacementCharCode1><matchCharCodeN><replacementCharCodeN>]
 *
 *
 *  @scope public
 */

exports.__doParse = __doParse

exports.addLayout = (l, layout) => {
    let code = l.code.split('-')
    const name = l.name
    const alpha = __doParse(l.normal)

    if (!isArray(alpha) || alpha.length !== 47)
        throw new Error(
            `VirtualKeyboard requires 'keys' property to be an array with 47 items, ${alpha.length} detected. Layout code: ${code}, layout name: ${name}`
        )

    /*
     *  overwrite keys with parsed data for future use
     */
    l.code = code[1] || code[0]
    l.normal = alpha
    l.domain = code[0]
    l.id = `${l.code} ${l.name}`
    /*
     *  don't rearrange already existing layouts
     */
    if (Object.prototype.hasOwnProperty.call(layout.hash, l.id)) {
        const lt = layout.hash[l.id]
        for (const z in l) {
            lt[z] = l[z]
        }
    } else {
        /*
         *  update list of the layout codes
         */
        if (!Object.prototype.hasOwnProperty.call(layout.codes, l.code)) {
            code = { name: l.code, layout: [] }
            layout.codes[l.code] = code
        } else {
            code = layout.codes[l.code]
        }

        layout.push(l)

        code.layout.push(l)

        layout.hash[l.id] = l

        /*
         *  update list of the layout codes
         */
        if (!Object.prototype.hasOwnProperty.call(layout.codes, l.code)) layout.codes[l.code] = l.code

        /*
         *  nice print of the layout
         */
        l.toString = function () {
            return this.id
        }

        /*
         *  reset hash, to be recalculated on options draw
         */
        layout.options = null
    }
}
