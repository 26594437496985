import React, { useCallback, useEffect, useState } from 'react'
import useUndo from 'use-undo'

import { Tooltip, IconButton } from '@material-ui/core'
import UndoIcon from '@material-ui/icons/Undo'
import RedoIcon from '@material-ui/icons/Redo'
import usePrevious from '../../Hooks/usePrevious.js'

const UndoRedoButtons = React.memo(function UndoRedoButtons({ text, setText, rtl }) {
    const [
        { present: presentText },
        { set: setUndoText, reset: resetText, undo: undoText, redo: redoText, canUndo, canRedo }
    ] = useUndo('')

    const [safety, setSafety] = useState(false)

    const prevText = usePrevious(presentText)

    useEffect(() => {
        if (text !== prevText) {
            if (prevText !== undefined) {
                setUndoText(text)
            } else {
                resetText(text)
            }
        }
    }, [prevText, resetText, setUndoText, text])

    useEffect(() => {
        if (safety && prevText !== undefined && presentText !== undefined && prevText !== presentText) {
            setText(presentText)
            setUndoText(presentText)
            setSafety(false)
        }
    }, [presentText, prevText, safety, setText, setUndoText])

    const undoRedo = useCallback(
        (which) => {
            if (!safety) {
                which === 'undo' && undoText()
                which === 'redo' && redoText()
                setSafety(true)
            }
            // window.dataLayer.push({
            //     event: 'button_click_event', // Not necessarily needed if this loads before GTM is initialized.
            //     button_name_var: which
            // })
        },
        [redoText, safety, undoText]
    )

    const handleClickUndo = useCallback(() => undoRedo('undo'), [undoRedo])
    const handleClickRedo = useCallback(() => undoRedo('redo'), [undoRedo])

    return (
        <>
            {/* <div style={{ width: '20px' }}> </div> */}
            <Tooltip title='Undo'>
                <span>
                    <IconButton
                        variant='contained'
                        color='primary'
                        onClick={handleClickUndo}
                        disabled={!canUndo}
                        aria-label='Undo'
                    >
                        {!rtl ? <UndoIcon fontSize={'small'} /> : <RedoIcon fontSize={'small'} />}
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title='Redo'>
                <span>
                    <IconButton
                        variant='contained'
                        color='primary'
                        onClick={handleClickRedo}
                        disabled={!canRedo}
                        aria-label='Redo'
                    >
                        {!rtl ? <RedoIcon fontSize={'small'} /> : <UndoIcon fontSize={'small'} />}
                    </IconButton>
                </span>
            </Tooltip>
        </>
    )
})

export default UndoRedoButtons
