export default function IpadCSS() {
    return (
        <style jsx global>{`
            @media (min-width: 720px) {
                .kbDesk.k_ma span.k_ca,
                .kbDesk.k_mc span.k_cc,
                .kbDesk.k_mn span.k_cn,
                .kbDesk.k_ms span.k_cs,
                .kbDesk.k_msa span.k_csa,
                .kbDesk.k_msc span.k_csc {
                    color: black;
                    bottom: 4px;
                    left: 5px;
                    position: absolute;
                    right: 0;
                }
                .kbDesk.k_ma span.k_csa,
                .kbDesk.k_mc span.k_csc,
                .kbDesk.k_mn span.k_ca,
                .kbDesk.k_mn span.k_cs,
                .kbDesk.k_ms span.k_csa,
                .kbDesk.k_ms span.k_csc {
                    color: grey;
                    font-size: 12px;
                    line-height: 1.1;
                    position: absolute;
                    left: 14px;
                    right: 0;
                    top: 3px;
                    vertical-align: bottom;
                    width: 100%;
                    z-index: 1;
                }
                .kbDesk.k_mn span.k_ca,
                .kbDesk.k_ms span.k_csa {
                    color: grey;
                    left: -12px;
                }

                .kbDesk span.deadKey {
                    color: red !important;
                }

                .kbDesk div.k_bd a span {
                    padding-left: 1px;
                    padding-top: 1px;
                    bottom: 3px !important;
                }

                .kbDesk div.k_b span {
                    display: block;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
                        'Helvetica Neue', sans-serif, 'Tahoma', 'Times New Roman', 'Arial';
                    font-size: 19px;
                    line-height: 23px;
                    font-weight: normal;
                    overflow: visible;
                    text-align: center;
                    text-indent: 0;
                    white-space: pre;
                }

                #kbDesk {
                    height: 91%;
                }
                #kbDesk div.k_bd div {
                    background-color: #bcbfc3;
                    border-bottom: 0 solid #898b8d;
                    box-shadow: inset 1px 1px 3px 0px #aaabad;
                }

                .kbDesk div#kb_benter.k_bd div,
                .kbDesk div#kb_bbackspace.k_bd div,
                .kbDesk div#kb_btab.k_bd div,
                .kbDesk div#kb_bcaps.k_bd div,
                .kbDesk div#kb_bshift_left.k_bd div,
                .kbDesk div#kb_bshift_right.k_bd div,
                .kbDesk div#kb_balt_left.k_bd div,
                .kbDesk div#kb_balt_right.k_bd div,
                .kbDesk div#kb_bctrl_left.k_bd div,
                .kbDesk div#kb_bctrl_right.k_bd div,
                .kbDesk div#kb_bdel.k_bd div {
                    background-color: #fcfcfc;
                }

                .virtualKeyboard div.loaderror {
                    color: #a22 !important;
                }

                #kb_benter.k_bd div::after,
                #kb_bbackspace.k_bd div::after,
                #kb_bshift_left.k_bd div::after,
                #kb_bshift_right.k_bd div::after,
                #kb_btab.k_bd div::after,
                #kb_bcaps.k_bd div::after,
                #kb_bctrl_left.k_bd div::after,
                #kb_bctrl_right.k_bd div::after,
                #kb_balt_left.k_bd div::after,
                #kb_balt_right.k_bd div::after,
                #kb_bdel.k_bd div::after {
                    color: black;
                }
            }
        `}</style>
    )
}
