import { isIE } from 'react-device-detect'

function div_module() {
    const self = this

    /**
     *  Returns selection start or end position in absolute chars from the field start
     *
     *  @param {HTMLInputElement, HTMLTextareaElement} el input or textarea to get position from
     *  @return {Number} offset from the beginning
     *  @scope private
     */
    self.getPos = (el) => {
        /*
         *  we need to calculate both start and end points, because range could be reversed
         *  but we can't move selection end point before start one
         */
        const sel = window.getSelection()
        let sn = sel.anchorNode
        let so = sel.anchorOffset
        let en = sel.focusNode
        let eo = sel.focusOffset
        let ss = false
        let es = false
        let sc = 0
        let ec = 0
        let cn
        const tw = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null, false)
        while (sn && sn.nodeType !== 3) {
            sn = sn.childNodes[so]
            so = 0
        }
        while (en && en.nodeType !== 3) {
            en = en.childNodes[eo]
            eo = 0
        }
        cn = tw.nextNode()
        while (cn) {
            if (cn === en) {
                ec += eo
                es = true
            }
            if (cn === sn) {
                sc += so
                ss = true
            }
            if (!es) ec += cn.nodeValue.length
            if (!ss) sc += cn.nodeValue.length
            if (es && ss) break
            cn = tw.nextNode()
        }

        // var caretPos = 0, sel, range;
        // // if (window.getSelection) {
        //   sel = window.getSelection();
        //   if (sel.rangeCount) {
        //     range = sel.getRangeAt(0);
        //     if (range.commonAncestorContainer.parentNode === el) {
        //       console.log(range)
        //       pos = [range.startOffset,range.endOffset]
        //     }
        //   }

        // } else if (document.selection && document.selection.createRange) {
        //   range = document.selection.createRange();
        //   if (range.parentElement() === editableDiv) {
        //     var tempEl = document.createElement("span");
        //     editableDiv.insertBefore(tempEl, editableDiv.firstChild);
        //     var tempRange = range.duplicate();
        //     tempRange.moveToElementText(tempEl);
        //     tempRange.setEndPoint("EndToEnd", range);
        //     caretPos = tempRange.text.length;
        //   }
        // }

        return [Math.min(ec, sc), Math.max(ec, sc)]
    }
    /**
     *  Removes the selection, if available
     *
     *  @param {HTMLElement} el field to delete text from
     *  @return {String} deleted substring
     *  @scope public
     */
    self.del = (el) => {
        const s = window.getSelection()
        let i = s.rangeCount
        while (--i > -1) s.getRangeAt(i).deleteContents()

        /*
         *  insert empty text node for browsers that loose selection, when it's empty
         */
        // console.log(s.rangeCount,s.anchorNode,s.focusNode)
        // if(s.rangeCount) {

        /*
         *  insert empty text node for browsers that loose selection, when it's empty
         */
        if (el.innerText === '') {
            // eslint-disable-next-line no-unused-expressions
            el.innerText === ''
            const r = document.createRange()
            r.setStart(el, 0)
            r.setEnd(el, 0)
            r.insertNode(document.createTextNode(''))
            if (document.body.createTextRange) {
                // All IE but Edge
                const range = document.body.createTextRange()
                range.collapse()
                range.select()
            } else {
                s.removeAllRanges()
            }

            s.addRange(r)
        }

        // if(isIE() && !s.anchorNode) {
        //    var r = s.getRangeAt(s.rangeCount-1)
        //   r.insertNode(document.createTextNode(""))
        //   s.addRange(r);
        // }
        // var r = s.getRangeAt(s.rangeCount-1)
        // r.insertNode(document.createTextNode(""))
        // s.addRange(r);

        // IE11 and Safari mobile index error:
        const r = document.createRange()
        if (!isIE) {
            if (s.anchorNode !== document) {
                if (s.anchorNode && s.anchorNode.nodeType) {
                    r.setStart(s.anchorNode, s.anchorOffset)
                    r.setEnd(s.focusNode, s.focusOffset)
                    r.insertNode(document.createTextNode(''))
                    s.removeAllRanges()
                    s.addRange(r)
                }
            } else {
                // android empty div error:
                r.setStart(el, 0)
                r.setEnd(el, 0)
                r.insertNode(document.createTextNode(''))
                s.removeAllRanges()
                s.addRange(r)
            }
        }
        //   else {
        //     // IE
        //     if (el.innerText === "") {
        //       // r.setStart(el, 0)
        //       // r.setEnd(el, 0)
        //       // r.insertNode(document.createTextNode(' '))
        //       // s.removeAllRanges()
        //       // s.addRange(r)
        //     }
        //   }

        // else {
        //   r.setStart(el ,s.anchorOffset);
        //   r.setEnd(el,s.focusOffset);
        // }

        // }

        // let selection = window.getSelection()
        // // if(selection.type==="Caret" && selection.anchorOffset !== 0) {
        // //     var range = document.createRange();
        // //     console.log(range)
        // //     range.setStart(selection.anchorNode, selection.anchorOffset-1);
        // //     range.setEnd(selection.anchorNode, selection.anchorOffset);
        // //     range.deleteContents();
        // // }
        // // else {
        //   selection.deleteFromDocument()
        // // }
    }
    /**
     *  Inserts text to the textarea
     *
     *  @param {HTMLElement} text field to insert text
     *  @param {String} text to insert
     *  @scope public
     */
    self.ins = (el, val) => {
        // console.log(val, 'val')
        // const isEmoji = val.type === 'span'
        // if (!isEmoji) {
        val = val.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')

        // TODO: check whats needed here (does nothing?)
        //    if(!isIE) {
        val = val.replace(/\r\n/g, '\n')
        val = val.replace(/[\r\n]/g, '\n')
        //          //      gate2home. fix for the weird bug (doesn't jump line on enter)
        //  if(val === "<br/>" && (!el.lastElementChild || el.lastElementChild.nodeName !== "BR") ) {
        //           el.appendChild(document.createElement("br"))
        //  }
        if (
            val.match(/[\r\n]/) &&
            el &&
            el.innerHTML &&
            el.innerHTML.length &&
            !el.innerHTML[el.innerHTML.length - 1].match(/[\r\n]/)
        ) {
            //  el.innerHTML += '\n'
            el.appendChild(document.createTextNode('\n'))
        }
        // }

        if (isIE) {
            val = val.replace(/\n/g, '\r')
        }
        // END TODO
        // }

        const n = document.createElement('span')
        const s = window.getSelection()
        let ln

        // IE11 and Safari mobile index error:
        const r = document.createRange()
        r.setStart(s.anchorNode, s.anchorOffset)
        r.setEnd(s.focusNode, s.focusOffset)

        // if(s.anchorNode) {
        //   r = s.getRangeAt(0)
        // }
        // else {
        //   r = document.createRange();
        // }

        // if (!isEmoji) {
        n.innerHTML = val
        // }
        r.insertNode(n)
        // if (isEmoji) {
        //     ReactDOM.render(val, n)
        // }
        r.selectNodeContents(n)

        const pn = n.parentNode
        ln = n.nextSibling

        /*
         *  replace holder node with the extracted document fragment
         */
        const newChild = r.extractContents()
        n.parentNode.replaceChild(newChild, n)

        /*
         *  if there's no last child, attempt to set range after the last child in the node
         */
        if (!ln) ln = pn.lastChild

        const r1 = document.createRange()
        /*
         *  if last node is text node
         */
        if (ln.nodeValue) {
            /*
             *  move selection to the very beginning of this node
             */
            r1.setStart(ln, 0)
        } else {
            /*
             *  otherwise, move selection after the newly created node,
             *  it's actual when creating line breaks
             */
            r1.setStartAfter(ln)
        }

        /*
         *  remove any existing selection
         *  and create the new one
         */
        s.removeAllRanges()
        s.addRange(r1)

        return self.getPos(el)[0]
    }
    /**
     *  Return contents of the current selection
     *
     *  @param {HTMLElement} el element to look position on
     *  @param {Number} s start position
     *  @param {Number} e end position
     *  @return {String}
     *  @scope public
     */
    self.getSelection = () =>
        //     var s = el.getSelection();
        //     return s?s.toString():"";
        // } else if (el.document && el.document.selection) {
        //     return el.document.selection.createRange().text;
        // }
        window.getSelection().toString()
    /**
     *  Sets the selection range
     *
     *  @param {HTMLElement}
     *  @param {Number} start position
     *  @param {Number} end position
     *  @return void
     *  @scope public
     */
    self.setRange = (el, start, end) => {
        const sel = window.getSelection()
        sel.removeAllRanges()
        const r = document.createRange()
        let cnt = 0
        let cn
        let pn
        const tw = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null, false)

        /*
         *  move start position to the very beginning of the first non-empty text node ( <= in the expression),
         *  it's actual when the actual selection is at the very beginning of the text node
         *  otherwise, selection will be started from the end of some previous node,
         *  this could lead to deletion of the intermediate non-text content
         */
        while ((cn = tw.nextNode()) && (!cn.nodeValue.length || cnt + cn.nodeValue.length < start)) {
            pn = cn
            cnt += cn.nodeValue.length
        }
        /*
         *  explicitly set range borders
         */
        if (cn || (cn = pn)) {
            r.setStart(cn, start - cnt)
            r.setEnd(cn, start - cnt)
        }
        if (cn) {
            do {
                if (cn.nodeType !== 3) continue
                if (cnt + cn.nodeValue.length < end) {
                    cnt += cn.nodeValue.length
                } else {
                    r.setEnd(cn, end - cnt)
                    break
                }
                cn = tw.nextNode()
            } while (cn)
        }
        sel.addRange(r)
    }
}

export default div_module
