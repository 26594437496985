import { Button } from '@material-ui/core'
import { memo, useState } from 'react'
import Image from 'next/image'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import dynamic from 'next/dynamic'
const EmojiKeyboard = dynamic(() => import('./EmojiKeyboard'))

const EmojiButton = memo(({ VK, active }) => {
    const [showEmoji, setShowEmoji] = useState(false)

    return (
        <>
            {showEmoji && <EmojiKeyboard VK={VK} />}
            <Button
                className={'EmojiButton'}
                variant='contained'
                size='large'
                color='primary'
                onClick={() => {
                    setShowEmoji(!showEmoji)
                }}
                style={{
                    backgroundColor: 'rgb(188, 191, 195)',
                    width: '77px',
                    height: '41px',
                    padding: '6px',
                    position: 'absolute',
                    left: '10px',
                    bottom: '32px',
                    zIndex: 200,
                    opacity: active ? undefined : 0.2
                }}
            >
                {!showEmoji ? (
                    <Image
                        alt={'Emoji Keyboard'}
                        width='65'
                        height='33'
                        src={'/images/emoji_button_tiny.png'}
                        priority={true}
                    />
                ) : (
                    <>
                        <ArrowBackIcon style={{ fontSize: '1.6rem', color: '#868686' }} />
                        <KeyboardIcon style={{ fontSize: '2rem', color: '#868686' }} />
                    </>
                )}
            </Button>
        </>
    )
})

EmojiButton.displayName = 'EmojiButton'
export default EmojiButton
