import { useEffect, useCallback } from 'react'
import { useT13nContext } from '../../Context/T13nContext'
import VK from './VirtualKeyboard.full.3.7.2/virtualkeyboard.js'

const useT13n = (sectionKey, kbLangKey, keyboardActive, layout, showEnglishKeyboard) => {
    const [t13nState, t13nDispatch] = useT13nContext()

    // useEffect(() => {
    //     VK.setIsT13n(t13nState.t13n)
    // }, [t13nState.t13n])

    useEffect(() => {
        VK.setDispatch(t13nDispatch) // :)
        VK.setIsT13n(t13nState.t13n) // :)
    }, [t13nDispatch, t13nState.t13n])

    useEffect(() => {
        if (t13nState.t13n && t13nState.done) {
            console.log('done!')
            VK.keyClicker('kb_bspace', true)
            t13nDispatch({ type: 'clear' })
        }
    }, [t13nDispatch, t13nState.done, t13nState.t13n])

    useEffect(() => {
        t13nDispatch({ type: 'clear' })
    }, [sectionKey, t13nDispatch])

    useEffect(() => {
        t13nDispatch({ type: 'activate-tansliteration', payload: false })
    }, [kbLangKey, t13nDispatch])

    useEffect(() => {
        if (t13nState.t13n || t13nState.stash) {
            if (keyboardActive) {
                t13nState.stash && !showEnglishKeyboard && t13nDispatch({ type: 'unstash' })
            } else {
                !t13nState.stash && !showEnglishKeyboard && t13nDispatch({ type: 'stash' })
            }
        }
    }, [keyboardActive, showEnglishKeyboard, t13nDispatch, t13nState.stash, t13nState.t13n])

    const toggleT13n = useCallback(() => {
        t13nDispatch({
            type: 'activate-tansliteration',
            payload: !t13nState.t13n
        })
    }, [t13nDispatch, t13nState.t13n])

    const showingT13nMenu = !!t13nState.textToCheck

    return [t13nState.t13n, t13nState.rtl, toggleT13n, showingT13nMenu]
}

export default useT13n
