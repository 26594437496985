import React, { useCallback } from 'react'

import { makeStyles, Tooltip, IconButton } from '@material-ui/core'

import useClipboard from '../../Pages/Keyboard/FreeText/useClipboard.js'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import DeleteIcon from '@material-ui/icons/Delete'
import UndoRedoButtons from './UndoRedoButtons.js'
import dynamic from 'next/dynamic'
const AlertDialog = dynamic(() => import('../AlertDialog'))
const CopySnackBar = dynamic(() => import('./CopySnackBar'))

const useStyles = makeStyles(() => ({
    root: {
        position: 'absolute',
        // width: '100%',
        bottom: '5px',
        display: 'flex',
        justifyContent: 'center',
        direction: ({ rtl }) => (rtl ? 'rtl' : false),
        // minWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'auto',

        left: ({ rtl }) => (!rtl ? 'auto' : '21px'),
        right: ({ rtl }) => (!rtl ? '21px' : 'auto')
    },
    somespace: { minWidth: '13px' }
}))

const selectAll = (element) => {
    if (document.body.createTextRange) {
        let range = document.body.createTextRange()
        range.moveToElementText(element)
        range.select()
    } else if (window.getSelection) {
        let selection = window.getSelection()
        let range = document.createRange()
        range.selectNodeContents(element)
        selection.removeAllRanges()
        selection.addRange(range)
    }
}

const EditBar = React.memo(({ editableRef, setText, ...props }) => {
    const classes = useStyles(props)
    const [snackBar, setSnackBar] = React.useState({
        open: false,
        text: '',
        severity: 'success',
        duration: 2000
    })
    const [showCopy, handleCopy] = useClipboard({ copyElementRef: editableRef })

    const handleClose = useCallback(
        (event, reason) => {
            if (reason === 'clickaway') {
                return
            }

            setSnackBar({ ...snackBar, open: false })
        },
        [snackBar]
    )

    const [openAreYouSure, setOpenAreYouSure] = React.useState(false)

    const handleCloseAreYouSure = useCallback(() => {
        setOpenAreYouSure(false)
        window.getSelection && window.getSelection().removeAllRanges()
    }, [])

    const handleClear = useCallback(() => {
        selectAll(editableRef.current)
        setOpenAreYouSure(true)
    }, [editableRef])

    const handleCopyClick = useCallback(() => {
        handleCopy()
        selectAll(editableRef.current)
        setSnackBar({
            text: 'Copied Successfully!',
            severity: 'success',
            open: true,
            duration: 2000
        })
    }, [editableRef, handleCopy])

    const handleApprove = useCallback(() => {
        setText('')
        setSnackBar({
            text: 'Text Cleared',
            severity: 'info',
            open: true,
            duration: 2000
        })
        handleCloseAreYouSure()
    }, [handleCloseAreYouSure, setText])

    return (
        <>
            <div className={classes.root}>
                <UndoRedoButtons setText={setText} {...props} />

                <span className={classes.somespace}></span>

                <Tooltip title='Clear'>
                    <IconButton variant='contained' color='primary' onClick={handleClear} aria-label='Clear'>
                        <DeleteIcon fontSize={'small'} />
                    </IconButton>
                </Tooltip>
                {showCopy && (
                    <Tooltip title='Copy'>
                        <IconButton variant='contained' color='primary' onClick={handleCopyClick} aria-label='Copy'>
                            <FileCopyIcon fontSize={'small'} />
                        </IconButton>
                    </Tooltip>
                )}
            </div>

            {openAreYouSure && (
                // <Suspense fallback={<Loader style={{ top: 0 }} />}>
                <AlertDialog
                    open={openAreYouSure}
                    id='AlertDialog'
                    setOpen={(open) => !open && handleCloseAreYouSure()}
                    handleApprove={handleApprove}
                    approveText='Yes, Clear All Text'
                    cancelText='Oops...'
                    title={'Are you sure?'}
                />
                // </Suspense>
            )}

            {snackBar.open && (
                // <Suspense fallback={<Loader style={{ top: 0 }} />}>
                <CopySnackBar
                    open={snackBar.open}
                    autoHideDuration={snackBar.duration}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={handleClose}
                    alertText={snackBar.text}
                    alertSeverity={snackBar.severity}
                />
                // </Suspense>
            )}
        </>
    )
})

export default EditBar
