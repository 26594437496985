import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useKeyboardContext } from '../../Context/KeyboardContext'
import { keyboardLanguages } from '../../StaticData/SiteData.js'
import Loader from '../Loader'

import { SwitchOnOff, SwitchRegular } from '../Switches'
// import { addLayouts } from './VirtualKeyboard.full.3.7.2/layouts/layouts.js'
// Virtual keyboard:
import VK from './VirtualKeyboard.full.3.7.2/virtualkeyboard.js'
import { local as storeLocal } from '../../Config/storage'
import useAutoMapping from './useAutoMapping'
import useT13n from './useT13n'
import KBDesk from './KBDesk'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import dynamic from 'next/dynamic'
import { useSearchContext } from '../../Context/SearchContext'

import EmojiButton from './EmojiButton'

const IMECSS = dynamic(() => import('./VirtualKeyboard.full.3.7.2/css/IMECSS'))

const layouts = process.env.layouts
const codeNeighbors = process.env.codeNeighbors
const keymapsGrouped = process.env.keymapsGrouped
const layouts_hash = process.env.vk_precalc_layouts_hash
VK.setLayouts(layouts_hash) // addLayouts(VK)

// TODO: find soultion to translating "Load Keyboard" without rerendering
const VirtualKeyboardDivNoUpdate = React.memo(
    ({ checked, kebyoardChars, virtualKeyboardDivRef, mapping, setMapping, layout }) => {
        const [, keyboardDispatch] = useKeyboardContext()
        const lang_code = layout.split(' ')[0]
        const lang_code_neighbors = codeNeighbors[lang_code]
        const handleMappingChange = (evt) => {
            setMapping(evt.target.value)
            VK.switchMappingExternal(evt.target.value)

            storeLocal.setItem('vk_mapping_is_user_set', true)
            // window.dataLayer.push({
            //     event: 'mapping_event', // Not necessarily needed if this loads before GTM is initialized.
            //     mapping_type_var: 'user',
            //     mapping_language_var: evt.target.value
            // })
            keyboardDispatch({
                type: 'update-value',
                payload: { key: 'selectedMapping', value: evt.target.value }
            })
        }

        const handleChange = (evt) => {
            VK.switchLayout(evt.target.value)
            keyboardDispatch({
                type: 'update-value',
                payload: { key: 'layout', value: evt.target.value }
            })
        }

        return (
            <>
                <div
                    id='virtualKeyboard'
                    ref={virtualKeyboardDivRef}
                    style={{
                        opacity: checked ? 1 : 0.2,
                        userSelect: 'none',
                        cursor: 'auto'
                    }}
                    className={`virtualKeyboard${!checked ? ' unchecked' : ''} ${layouts_hash[layout].domain}`}
                >
                    <style jsx>{`
                        .virtualKeyboard {
                            -webkit-tap-highlight-color: transparent;
                            width: 712px !important;
                            height: 257px;
                            border: 0;
                            margin-top: -5px;
                            transition-property: all;
                            transition-duration: 0.3s;
                            transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
                            border-bottom-right-radius: 5px;
                            border-bottom-left-radius: 5px;
                        }
                        .virtualKeyboard select {
                            height: 24px;
                        }
                        .virtualKeyboard select#kb_langselector,
                        .virtualKeyboard select#kb_mappingselector {
                            border: 1px solid black;
                            width: 125px;
                            direction: ltr;
                            margin-right: 9px;
                        }
                        .virtualKeyboard select#kb_langselector {
                            width: 160px;
                        }
                        .virtualKeyboard select#kb_mappingselector {
                            margin-right: 5px;
                        }
                        .virtualKeyboard select,
                        .virtualKeyboard select option {
                            background: #fff;
                            font-family: Arial, Tahoma, Verdana sans-serif;
                            font-size: 12px;
                        }
                        .virtualKeyboard select optgroup option {
                            padding-left: 20px;
                        }
                        .virtualKeyboard .kb_selectors {
                            position: relative;
                            text-align: right;
                            direction: rtl;
                            display: flex;
                        }
                         {
                            /* @media (max-width: 720px) {
                            .virtualKeyboard {
                                width: 444px !important;
                                height: 212px !important;
                                margin-top: 0;
                            }
                        } */
                        }
                    `}</style>
                    <KBDesk kebyoardChars={kebyoardChars} onMouseDown={VK._btnMousedown_} onMouseUp={VK._btnClick_} />
                    <div className='kb_selectors'>
                        <select
                            id='kb_langselector'
                            aria-label='Keyboard Language'
                            onChange={handleChange}
                            onBlur={handleChange}
                            defaultValue={layout}
                        >
                            {Object.keys(layouts).map(
                                (opt) =>
                                    lang_code_neighbors.includes(opt) && (
                                        <optgroup label={opt} key={opt}>
                                            {layouts[opt].map((lay) => (
                                                <option value={opt + ' ' + lay} key={opt + ' ' + lay} label={lay}>
                                                    {lay}
                                                </option>
                                            ))}
                                        </optgroup>
                                    )
                            )}
                        </select>
                        <select
                            id='kb_mappingselector'
                            onChange={handleMappingChange}
                            onBlur={handleMappingChange}
                            value={mapping}
                            aria-label='Keyboard Mapping'
                        >
                            {Object.keys(keymapsGrouped).map((opt) => (
                                <optgroup label={opt} key={opt}>
                                    {keymapsGrouped[opt].map((keymap) => (
                                        <option value={opt + ' ' + keymap} key={keymap} label={keymap}>
                                            {keymap.split(' ')}
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </select>
                    </div>
                </div>
            </>
        )
    }
)
VirtualKeyboardDivNoUpdate.displayName = 'VirtualKeyboardDivNoUpdate'

const desktop_size = { width: 712, height: 257 }
const mobile_size = { width: 444, height: 214 }

const VirtualKeyboard = React.memo(function VirtualKeyboard({
    layout2,
    kbLangKey,
    sectionKey,
    t
    // hideLayouts
}) {
    const [keyboardState, keyboardDispatch] = useKeyboardContext()
    const [searchState] = useSearchContext()

    const [layout, setLayout] = useState(layout2)
    const [mapping, setMapping] = useState('QWERTY Default')

    const isDesktopMedia_ = useMediaQuery('(min-width: 720px)')
    const [isDesktopMedia, setisDesktopMedia] = useState(true)

    useEffect(() => {
        setisDesktopMedia(isDesktopMedia_)
    }, [isDesktopMedia_])

    useEffect(() => {
        const keymap = storeLocal.getItem('vk_mapping')
        if (keymap) {
            setMapping(keymap)
        }
    }, [])

    useEffect(() => {
        if (keyboardState.layout) {
            setLayout(keyboardState.layout)
        }
    }, [keyboardState.layout])

    useEffect(() => {
        setLayout(layout2)
    }, [layout2])

    const [isT13n, t13nRtl, toggleT13n, showingT13nMenu] = useT13n(
        sectionKey,
        kbLangKey,
        keyboardState.active,
        layout,
        keyboardState.showEnglishKeyboard
    )

    const [windowSize, setWindowSize] = useState(
        typeof window !== 'undefined'
            ? { width: window.innerWidth, height: window.innerHeight }
            : { width: 1280, height: 960 }
    )
    const [style, setStyle] = useState(desktop_size)
    const virtualKeyboardDivRef = useRef(null)

    const [kebyoardChars, setKebyoardChars] = useState({})

    useAutoMapping(isDesktopMedia, setMapping)

    const [loadedIME, setloadedIME] = useState(false)

    const handleSwitchOnOff = useCallback(() => {
        keyboardDispatch({ type: 'set-active', payload: !keyboardState.active })
    }, [keyboardDispatch, keyboardState.active])

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key == 'Escape' && !showingT13nMenu) {
                handleSwitchOnOff()
            }
        }
        document.addEventListener('keydown', handleKeyPress, true)
        return () => {
            document.removeEventListener('keydown', handleKeyPress, true)
        }
    }, [handleSwitchOnOff, keyboardState.focusInputId, showingT13nMenu])

    useEffect(() => {
        VK.setSetKebyoardChars(setKebyoardChars) // :)

        VK.init(layout, virtualKeyboardDivRef.current, setloadedIME)

        const resizeListner = () => setWindowSize({ width: window.innerWidth, height: window.innerHeight })
        window.addEventListener('resize', resizeListner)

        return () => {
            window.removeEventListener('resize', resizeListner)
        }
    }, [layout])

    useEffect(() => {
        keyboardDispatch({ type: 'set-active', payload: true })
    }, [keyboardDispatch, layout, sectionKey])

    useEffect(() => {
        if (keyboardState.active) {
            VK.attachInput(keyboardState.focusInputId)
        } else {
            VK.detachInput()
        }
    }, [keyboardState.active, keyboardState.focusInputId])
    //order is important, above before the one below
    useEffect(() => {
        if (keyboardState.active) {
            if (keyboardState.showEnglishKeyboard || isT13n) {
                VK.switchLayout('US US')
            } else {
                VK.switchLayout(layout)
            }
        }
    }, [isT13n, keyboardState.active, keyboardState.showEnglishKeyboard, layout, kbLangKey, sectionKey]) //needs to have kbLangKey, sectionKey

    useEffect(() => {
        if (!isDesktopMedia && keyboardState.active) {
            const scale_ratio = Math.min(windowSize.width, desktop_size.width) / mobile_size.width
            const height = mobile_size.height * scale_ratio
            setStyle({
                transform: `scale(${scale_ratio})`,
                height: height + 'px'
            })
        }
    }, [windowSize, keyboardState.active, isDesktopMedia])

    return (
        <div
            id='keyboard'
            style={style}
            className={`VKeyboard 
    ${!(searchState && searchState.showKeyboard) ? 'slideup' : ''} 
    ${!keyboardState.active ? 'slideoff' : ''} 
    ${isDesktopMedia ? 'scale1' : ''}
    ${!isDesktopMedia ? 'mobileKeyboard' : ''}`}
        >
            <style jsx>{`
                .VKeyboard {
                    display: inline-block;
                    clear: left;
                    padding: 2.9px;
                    width: 712px;

                    max-height: 500px;

                    background-color: #d5d5d8;
                    border-radius: 6px;
                    border: 1px solid #cccccc;

                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                    position: relative;

                    vertical-align: middle;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                }
                @media (max-width: 720px) {
                    .VKeyboard {
                        overflow: visible;
                        margin-left: 0;
                        z-index: 100;
                        transform-origin: 0px 0px 0px;
                        left: 0;
                        bottom: 0;
                    }
                }
                .transliterate_check {
                    position: absolute;
                    bottom: -4px;
                    z-index: 100;
                    /* right: 5px; */
                    left: auto;
                    /* background-color: white; */
                    /* padding-left: 93px; */
                    left: 100px;
                }
                .keyboardCheck {
                    left: 11px;
                    position: absolute;
                    bottom: -4px;
                }
                .scale1 {
                    transform: scale(1) !important;
                    height: inherit !important;
                    bottom: inherit !important;
                }
                .slideup {
                    overflow-y: hidden;
                    max-height: 0;
                    padding: 0;
                    margin: 0;
                    border: 0;
                }
                .slideoff {
                    overflow-y: hidden;
                    overflow-x: hidden;
                    pointer-events: none;
                }
            `}</style>

            <VirtualKeyboardDivNoUpdate
                checked={keyboardState.active}
                // hideLayouts={hideLayoutsLanguages.includes(kbLangKey)}
                isDesktopMedia={isDesktopMedia}
                virtualKeyboardDivRef={virtualKeyboardDivRef}
                // enableKeyboardRef={enableKeyboardRef}
                kebyoardChars={kebyoardChars}
                layout={layout}
                mapping={mapping}
                setMapping={setMapping}
            />

            <>
                {isDesktopMedia && (
                    <>
                        <div className={`keyboardCheck ${!keyboardState.active ? 'unchecked' : ''}`}>
                            <SwitchOnOff
                                checked={keyboardState.active}
                                onChange={handleSwitchOnOff}
                                tooltip='ON / OFF (Escape)'
                            />
                        </div>

                        <div className={`transliterate_check ${t13nRtl || sectionKey === 'trans' ? 'rtl' : ''}`}>
                            {keyboardLanguages.data[kbLangKey].t13n && keyboardState.active && (
                                <SwitchRegular checked={isT13n} onChange={toggleT13n} label={t('UI.Transliteration')} />
                            )}
                        </div>

                        <EmojiButton VK={VK} active={keyboardState.active} />
                    </>
                )}
                <div className='ajax-loader' style={{ top: '-247px' }}>
                    {Object.keys(kebyoardChars).length === 0 && kebyoardChars.constructor === Object && <Loader />}
                </div>
            </>
            {loadedIME && <IMECSS />}
        </div>
    )
})

export default VirtualKeyboard
