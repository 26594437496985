import KeyboardButton from './KeyboardButton'
import React from 'react'

const rangexy = (start, end) => Array.from({ length: end - start }, (v, k) => k + start)

const buttonIds = [
    ...rangexy(0, 14),
    'backspace',
    'tab',
    ...rangexy(14, 26),
    'enter',
    'caps',
    ...rangexy(26, 37),
    'shift_left',
    ...rangexy(37, 47),
    'shift_right',
    'del',
    'ctrl_left',
    'alt_left',
    'space',
    'alt_right',
    'ctrl_right'
]

const KBDesk = React.memo(({ kebyoardChars, onMouseDown, onMouseUp }) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
        id='kbDesk'
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onClick={(e) => {
            e.preventDefault()
        }}
        className='kbDesk'
    >
        <style jsx global>{`
            #kbDesk.k_ma span.k_hia,
            #kbDesk.k_ma span.k_cc,
            #kbDesk.k_ma span.k_cn,
            #kbDesk.k_ma span.k_cs,
            #kbDesk.k_ma span.k_csc,
            #kbDesk.k_mn span.k_cc,
            #kbDesk.k_mn span.k_csa,
            #kbDesk.k_mn span.k_csc,
            #kbDesk.k_mn span.k_his,
            #kbDesk.k_ms span.k_his,
            #kbDesk.k_ms span.k_ca,
            #kbDesk.k_ms span.k_cc,
            #kbDesk.k_ms span.k_cn,
            #kbDesk.k_ms span.k_csc,
            #kbDesk.k_msa span.k_ca,
            #kbDesk.k_msa span.k_cc,
            #kbDesk.k_msa span.k_cn,
            #kbDesk.k_msa span.k_cs,
            #kbDesk.k_msa span.k_csc,
            #kbDesk.k_msac span.k_ca,
            #kbDesk.k_msac span.k_cc,
            #kbDesk.k_msac span.k_cn,
            #kbDesk.k_msac span.k_cs,
            #kbDesk.k_msac span.k_csa,
            #kbDesk.k_msac span.k_csc,
            #kbDesk.k_msc span.k_ca,
            #kbDesk.k_msc span.k_cc,
            #kbDesk.k_msc span.k_cn,
            #kbDesk.k_msc span.k_cs,
            #kbDesk.k_msc span.k_csa,
            #kbDesk.k_mc span.k_hic,
            #kbDesk.k_mc span.k_ca,
            #kbDesk.k_mc span.k_cn,
            #kbDesk.k_mc span.k_cs,
            #kbDesk.k_mc span.k_csa {
                display: none;
            }
            #kbDesk.k_ma span.k_ca,
            #kbDesk.k_mc span.k_cc,
            #kbDesk.k_mn span.k_cn,
            #kbDesk.k_ms span.k_cs,
            #kbDesk.k_msa span.k_csa,
            #kbDesk.k_msc span.k_csc {
                display: block !important;
            }
            .kbDesk.k_ha span.k_ca,
            .kbDesk.k_ha span.k_caShift,
            .kbDesk.k_hs span.k_cs,
            .kbDesk.k_hs span.k_csc {
                font-weight: bold;
            }

            @media (min-width: 720px) {
                .virtualKeyboard * {
                    border: 0;
                    color: black;
                    cursor: pointer;
                    margin: 0;
                    padding: 0;
                    user-select: none;
                }
                .virtualKeyboard div.kbDesk {
                    display: block;
                    padding: 0;
                    position: relative;
                    font-size: 1px;
                    overflow: hidden;
                    -moz-user-select: none;
                    -khtml-user-select: none;
                    margin-left: 6px;
                    margin-top: 4px;
                    user-select: none;
                    cursor: auto;
                }
                .kbDesk div.k_b {
                    -webkit-touch-callout: none;
                    float: left;
                    height: 40px;
                    overflow: hidden;
                    padding: 2px 0 4px 0;
                    margin: 0;
                    position: relative;
                    width: 47px;
                    z-index: 2;
                    /* overflow: unset;
        */
                }
                .kbDesk div.k_b div span {
                    color: transparent;
                }
                .kbDesk div.k_b div {
                    background: #fcfcfc;
                    display: block;
                    height: 107%;
                    position: relative;
                    text-decoration: none;
                    width: 89%;
                    border-radius: 4.5px;
                    border-bottom: 1px solid #898b8d;
                }
                .kbDesk div.k_b span.title {
                    display: none;
                }
                .kbDesk div#kb_bshift_right,
                .kbDesk div#kb_bdel,
                .kbDesk div#kb_bctrl_left,
                .kbDesk div#kb_balt_left,
                .kbDesk div#kb_bspace,
                .kbDesk div#kb_bctrl_right,
                .kbDesk div#kb_balt_right {
                    top: -3px;
                }
                .kbDesk div#kb_benter {
                    margin-top: -41px;
                    position: relative;
                    float: right;
                    height: 81px;
                    width: 100px;
                    z-index: -1;
                    top: -4px;
                    padding-top: 6px;
                    margin-bottom: -1px;
                    padding-bottom: -9px;
                }
                .kbDesk div#kb_benter[id] {
                    z-index: 1;
                }

                .kbDesk div#kb_bdel {
                    width: 46px;
                }
                .kbDesk div#kb_btab {
                    width: 70px;
                }

                .kbDesk div#kb_bcaps div,
                .kbDesk div#kb_btab div,
                .kbDesk div#kb_benter div,
                .kbDesk div#kb_bdel div,
                .kbDesk div#kb_balt_left div,
                .kbDesk div#kb_balt_right div,
                .kbDesk div#kb_bshift_left div,
                .kbDesk div#kb_bshift_right div,
                .kbDesk div#kb_bbackspace div,
                .kbDesk div#kb_bctrl_left div,
                .kbDesk div#kb_bctrl_right div {
                    background-color: #bcbfc3;
                    width: 92%;
                }
                .kbDesk div#kb_benter div {
                    top: -4px;
                    width: 96px;
                }
                .kbDesk div#kb_bspace div {
                    width: 89.5%;
                }
                .kbDesk div#kb_bcaps {
                    width: 88px;
                }
                .kbDesk div#kb_balt_left {
                    left: -31px;
                }
                .kbDesk div#kb_balt_right {
                    left: -67px;
                }
                .kbDesk div#kb_bshift_left {
                    width: 111px;
                    margin-right: -3px;
                }
                .kbDesk div#kb_bshift_right {
                    width: 85px;
                    margin-right: -3px;
                }
                .kbDesk div#kb_bctrl_left,
                .kbDesk div#kb_bctrl_right {
                    float: left;
                    width: 65px;
                }
                .kbDesk div#kb_bctrl_right {
                    float: right;
                }
                .kbDesk div#kb_bspace {
                    width: 382px;
                    left: -32px;
                }
                .kbDesk div#kb_bctrl_left,
                .kbDesk div#kb_bctrl_right {
                    visibility: hidden;
                }
                .kbDesk div#kb_balt_left,
                .kbDesk div#kb_balt_right {
                    width: 80px;
                }
                .kbDesk div#kb_balt_left {
                    margin-left: 48px;
                    position: relative;
                    float: left;
                    padding-left: 0;
                }
                #kb_benter::before {
                    content: 'f';
                    font-size: 15px;
                    z-index: 1;
                    background: #cfd2d6;
                    display: block;
                    height: 125px;
                    position: relative;
                    text-decoration: none;
                    width: 89%;
                    border-radius: 4.5px;
                    top: -82px;
                    left: -60px;
                    margin-top: 4px;
                    position: absolute;
                }

                #kb_bshift_left div::after,
                #kb_bshift_right div::after,
                #kb_btab div::after,
                #kb_benter div::after,
                #kb_bbackspace div::after,
                #kb_bcaps div::after,
                #kb_bdel div::after,
                #kb_balt_right div::after,
                #kb_balt_left div::after {
                    content: 'alt';
                    color: white;
                    font: menu;
                    top: 21px;
                    position: absolute;
                    left: -7px;
                    width: 45px;
                    height: 45px;
                    font-size: 12px;
                }
                #kb_bshift_left div::after {
                    content: '⇧';
                    top: 12px;
                    font-size: 16px;
                }
                #kb_bshift_right div::after {
                    content: '⇧';
                    top: 11px;
                    left: 34px;
                    font-size: 16px;
                }
                #kb_btab div::after {
                    content: '⇥';
                    top: 9px;
                    left: 0;
                    width: 36px;
                    height: 37px;
                    font-size: 19px;
                }
                #kb_bcaps div::after {
                    content: '⇪';
                    top: 10px;
                    font-size: 16px;
                }
                #kb_bdel div::after {
                    content: 'del';
                }
                #kb_bbackspace div::after {
                    content: '⌫';
                    top: 10px;
                    left: 4px;
                    width: 29px;
                    height: 21px;
                    z-index: 24;
                    font-size: 18px;
                }
                #kb_benter div::after {
                    content: '↵';
                    top: 56px;
                    left: 56px;
                    width: 36px;
                    height: 37px;
                    font-size: 19px;
                }
            }
        `}</style>
        {buttonIds.map((buttonId) => (
            <KeyboardButton
                key={`kb_b${buttonId}`}
                buttonId={buttonId}
                charArray={kebyoardChars ? kebyoardChars[buttonId] : ['', '', '', '', '']}
            />
        ))}
    </div>
))
KBDesk.displayName = 'KBDesk'

export default KBDesk
