import React, { useCallback, useEffect, useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import { browserVersion, isIE, isIOS } from 'react-device-detect'
import { escapedToHtml } from '../aux2.js'
import { useGate2HomeContext } from '../Context/Gate2HomeContext'
import { useKeyboardContext } from '../Context/KeyboardContext'
import { useT13nContext } from '../Context/T13nContext'
import EditBar from './EditBar/EditBar.js'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTextboxContext } from '../Context/TextboxContext.js'

const PersistentContentEditable = React.memo(function PersistentContentEditable({
    dataSaveId,
    onFocus,
    onChange,
    onClick,
    onBlur,
    onKeyDown,
    className,
    typeHereString = '',
    innerRef,
    oneliner = false,
    focus = false,
    adaptiveFontSize = false,
    englishOnly = false,
    showEditBar = false,
    rtl = false,
    ...props
}) {
    const [keyboardState, keyboardDispatch] = useKeyboardContext()
    const [Gate2homeState, Gate2homeDispatch] = useGate2HomeContext()
    const [TextboxState, TextboxDispatch] = useTextboxContext()

    const [t13nState, t13nDispatch] = useT13nContext()

    const [fontSizeClass, setFontSizeClass] = useState('len0')

    const ghostInput = useRef(null)
    const calcCaretRef = useRef(null)
    const caretRef = useRef(null)

    const isMobileMedia = useMediaQuery('(max-width: 720px)')
    const isDesktopMedia = useMediaQuery('(min-width: 720px)')

    //SSR hydration causes problems, these assures mediaqueries are updated on client:
    const [mediaQueries, setMediaQueries] = useState(false)
    useEffect(() => {
        setMediaQueries({ isMobileMedia, isDesktopMedia })
    }, [isMobileMedia, isDesktopMedia])

    const [isIOSClient, setIsIOSClient] = useState(false)
    useEffect(() => {
        setIsIOSClient(isIOS)
    }, [])

    useEffect(() => {
        if (focus && mediaQueries.isDesktopMedia) {
            innerRef.current.focus()
        }
    }, [focus, innerRef, mediaQueries.isDesktopMedia])

    useEffect(() => {
        innerRef.current.setAttribute('contenteditable', !(mediaQueries.isMobileMedia && keyboardState.active))
    }, [innerRef, mediaQueries.isMobileMedia, keyboardState.active])

    const updateT13nBoxPosition = useCallback(() => {
        if (mediaQueries.isDesktopMedia && innerRef && innerRef.current) {
            import('caret-pos').then(({ position }) => {
                try {
                    const pos = position(innerRef.current)
                    pos.top += innerRef.current.getBoundingClientRect().top
                    pos.left += innerRef.current.getBoundingClientRect().left
                    t13nState.t13n &&
                        t13nDispatch({
                            type: 'update-return-position',
                            payload: pos
                        })
                    delete pos.height
                    innerRef.current.focus()
                } catch (e) {
                    // error, skip it
                }
            })
        }

        if (mediaQueries.isMobileMedia) {
            t13nDispatch({
                type: 'update-return-position',
                payload: {
                    top: Math.ceil(document.querySelector('#virtualKeyboard').getBoundingClientRect().y - 3),
                    left: 'auto'
                }
            })
        }
    }, [innerRef, mediaQueries.isDesktopMedia, mediaQueries.isMobileMedia, t13nDispatch, t13nState.t13n])

    useEffect(() => {
        t13nState.t13n && updateT13nBoxPosition()
    }, [t13nState.t13n, updateT13nBoxPosition])

    useEffect(() => {
        caretRef && caretRef.current && (caretRef.current.style.display = keyboardState.active ? 'block' : 'none')
    }, [keyboardState.active])

    const insertCaretAtCursor = useCallback(() => {
        if (keyboardState.active) {
            const elm = innerRef.current
            const caret = caretRef.current
            const calc_caret = calcCaretRef.current

            const s = window.getSelection()
            const r = document.createRange()
            // for weird no newline if no element at the end

            if (s.anchorOffset === s.focusOffset) {
                if (s.anchorNode) {
                    // soultion for IE and safari mobile
                    r.setStart(s.anchorNode, s.anchorOffset)
                    r.setEnd(s.focusNode, s.focusOffset)
                } else {
                    r.setStart(elm, s.anchorOffset)
                    r.setEnd(elm, s.focusOffset)
                }

                r.deleteContents()

                r.insertNode(calc_caret)

                // The char that makes the caret not break number sequances or arabic connected chars:
                // https://en.wikipedia.org/wiki/Zero-width_joiner
                const zwj = document.createTextNode('\u200D')
                r.insertNode(zwj)

                // addClass(caret,"text_caret_mobile")
                const caret_height = parseInt(window.getComputedStyle(elm).lineHeight, 10)
                caret.style.height = caret_height + 'px'
                caret.style.left = calc_caret.offsetLeft + 'px'
                caret.style.right = calc_caret.offsetLeft + 'px'
                caret.style.top = calc_caret.offsetTop + 'px'

                zwj.remove()
                document.body.appendChild(calc_caret)
                elm.normalize()
                // elm.flag = true;
            }
        }
    }, [keyboardState.active, innerRef])

    const saveValueToLocalStorage = useCallback(() => {
        if (dataSaveId) {
            TextboxDispatch({
                type: 'save-storage',
                payload: { key: dataSaveId }
            })
        }
    }, [dataSaveId, TextboxDispatch])

    useEffect(() => {
        TextboxDispatch({
            type: 'retreive-storage',
            payload: [dataSaveId]
        })
        if (mediaQueries.isMobileMedia && !isIOSClient) {
            insertCaretAtCursor()
        }

        const timeout = setTimeout(() => {
            saveValueToLocalStorage()
        }, 60000)

        window.addEventListener('beforeunload', saveValueToLocalStorage)
        return () => {
            clearTimeout(timeout)
            window.removeEventListener('beforeunload', saveValueToLocalStorage)
            saveValueToLocalStorage()
        }
    }, [
        dataSaveId,
        insertCaretAtCursor,
        isIOSClient,
        mediaQueries.isMobileMedia,
        TextboxDispatch,
        saveValueToLocalStorage
    ])

    useEffect(() => {
        const query = Gate2homeState.query && (Gate2homeState.query.q || Gate2homeState.query.t)
        if (query) {
            TextboxDispatch({
                type: 'update-value',
                payload: { key: dataSaveId, value: escapedToHtml(query) }
            })
        }
    }, [Gate2homeState.query, TextboxDispatch, dataSaveId])

    const handleChange = useCallback(
        (evt) => {
            if (TextboxState[dataSaveId] !== evt.target.value) {
                onChange && onChange()

                if (adaptiveFontSize && evt.target.value) {
                    const len = evt.target.value.length
                    setFontSizeClass(len > 85 ? (len > 170 ? 'len170' : 'len85') : 'len0')
                }

                mediaQueries.isMobileMedia && !isIOSClient && insertCaretAtCursor()

                // setText(evt.target.value)
                TextboxDispatch({
                    type: 'update-value',
                    payload: { key: dataSaveId, value: evt.target.value }
                })

                mediaQueries.isMobileMedia && t13nState.t13n && updateT13nBoxPosition()
            }
        },
        [
            TextboxDispatch,
            TextboxState,
            adaptiveFontSize,
            dataSaveId,
            insertCaretAtCursor,
            isIOSClient,
            mediaQueries.isMobileMedia,
            onChange,
            t13nState.t13n,
            updateT13nBoxPosition
        ]
    )

    const handleTextAreaBlurIos = useCallback(() => {
        keyboardState.active && innerRef.current.setAttribute('readonly', true)
        !keyboardState.active && innerRef.current.removeAttribute('readonly')
        onBlur && onBlur()
    }, [innerRef, keyboardState.active, onBlur])

    const handleFocus = useCallback(
        (e) => {
            if (keyboardState.active) {
                onFocus && onFocus()
                // if(englishOnly) showEnglishKeyboard(true);
                keyboardDispatch({ type: 'focus-input', payload: e.target.id })
                // saveValueToLocalStorage()
                mediaQueries.isMobileMedia && !isIOSClient && (caretRef.current.style.display = 'block')
                // keyboardState.t13n && updateT13nBoxPosition()
            }
        },
        [isIOSClient, mediaQueries.isMobileMedia, keyboardDispatch, keyboardState.active, onFocus]
    )

    useEffect(() => {
        if (Gate2homeState.mobileLoadKeyboard) {
            innerRef.current.focus()
        }
    }, [Gate2homeState.mobileLoadKeyboard, innerRef])

    const handleBlur = useCallback(() => {
        mediaQueries.isMobileMedia && (caretRef.current.style.display = 'none')
        onBlur && onBlur()
    }, [mediaQueries.isMobileMedia, onBlur])

    const handleClickJoint = useCallback(() => {
        if (mediaQueries.isMobileMedia && !Gate2homeState.mobileLoadKeyboard)
            Gate2homeDispatch({ type: 'mobile-load-keyboard' })
        // if(englishOnly) showEnglishKeyboard(true);
        if (t13nState.t13n) updateT13nBoxPosition()

        // t13nState.t13n && t13nState.textToCheck && (t13nDispatch({ type: "clear"}));

        // console.log("t13nState.textToCheck",t13nState.textToCheck)
        // console.log("keyboardState.mobileLoadKeyboard",keyboardState.mobileLoadKeyboard)

        // if(!keyboardState.mobileLoadKeyboard) {
        //   document.execCommand('selectAll', false, null);
        // }
        // if(mediaQueries.isMobileMedia && !keyboardState.mobileLoadKeyboard) {
        //   keyboardDispatch({ type: "mobile-load-keyboard"});
        // }
    }, [
        Gate2homeDispatch,
        Gate2homeState.mobileLoadKeyboard,
        mediaQueries.isMobileMedia,
        t13nState.t13n,
        updateT13nBoxPosition
    ])

    const handleTextAreaClickIos = useCallback(() => {
        if (keyboardState.active) {
            onClick && onClick()
            handleClickJoint()
            innerRef.current.focus()
            setTimeout(function () {
                innerRef.current.removeAttribute('readonly')
            }, 100)
        }
    }, [handleClickJoint, innerRef, keyboardState.active, onClick])

    const handleKeyDown = useCallback(
        (e) => {
            if (keyboardState.active) {
                if (t13nState.t13n) {
                    if (t13nState.textToCheck) {
                        let action = ''

                        // TODO: unite with transliterateBox.js (same code)
                        ;(e.keyCode === 40 || e.keyCode === 37) && (action = 'choice-left') // down/left arrow
                        ;(e.keyCode === 38 || e.keyCode === 39) && (action = 'choice-right') // up/right arrow
                        e.keyCode === 27 && (action = 'clear') // escape
                        ;(e.keyCode === 13 || e.keyCode === 32) && (action = 'done') // enter and space

                        action && t13nDispatch({ type: action })
                        action && e.preventDefault()
                    } else {
                        if (e.keyCode !== 13 && e.keyCode !== 32 && e.keyCode !== 8) {
                            updateT13nBoxPosition() // enter space and backspace
                        }
                        onKeyDown && onKeyDown(e)
                    }
                } else {
                    onKeyDown && onKeyDown(e)
                }
            }
        },
        [keyboardState.active, onKeyDown, t13nDispatch, t13nState.textToCheck, t13nState.t13n, updateT13nBoxPosition]
    )

    const setEditBarText = useCallback(
        (val) =>
            TextboxDispatch({
                type: 'update-value',
                payload: { key: dataSaveId, value: val }
            }),
        [dataSaveId, TextboxDispatch]
    )

    const handleContainerFocus = useCallback(() => {
        englishOnly && keyboardDispatch({ type: 'english-keyboard', payload: true })
        englishOnly && t13nDispatch({ type: 'stash' })
    }, [englishOnly, keyboardDispatch, t13nDispatch])

    const handleContainerBlur = useCallback(() => {
        englishOnly && keyboardDispatch({ type: 'english-keyboard', payload: false })
        englishOnly && t13nDispatch({ type: 'unstash' })
        mediaQueries.isMobileMedia && isIOSClient && keyboardDispatch({ type: 'focus-input', payload: false })
    }, [englishOnly, isIOSClient, mediaQueries.isMobileMedia, keyboardDispatch, t13nDispatch])

    const handleContainerClick = useCallback(() => {
        if (t13nState.t13n) {
            t13nState.textToCheck && t13nDispatch({ type: 'clear' })
        }
    }, [t13nDispatch, t13nState.textToCheck, t13nState.t13n])

    const handleClick = useCallback(
        (e) => {
            handleClickJoint()

            if (isIE && browserVersion <= 10 && e.target.innerHTML) {
                // fix <=ie10 - jumps to next line when trying to add chars to end of line
                var startLine =
                    e.pageX - e.target.getBoundingClientRect().left < 15 ||
                    e.target.getBoundingClientRect().left + e.target.offsetWidth - e.pageX < 15
                var range = document.selection.createRange()
                range.moveStart('character', -1)
                range.select()
                if (!range.text[0] && !startLine) {
                    range.moveEnd('character', -1)
                } else {
                    range.moveStart('character', range.text[0].length)
                }
                range.select()
            }

            mediaQueries.isMobileMedia && insertCaretAtCursor()
            onClick && onClick()
        },
        [handleClickJoint, insertCaretAtCursor, mediaQueries.isMobileMedia, onClick]
    )

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            onKeyDown={handleKeyDown}
            onClick={handleContainerClick}
            onFocus={handleContainerFocus}
            onBlur={handleContainerBlur}
            className='Persistent'
        >
            <style jsx>
                {`
                    .Persistent {
                        width: 100%;
                        height: 100%;
                        position: relative;
                    }
                    @keyframes blinker {
                        0% {
                            opacity: 1;
                        }
                        49% {
                            opacity: 1;
                        }
                        50% {
                            opacity: 0;
                        }
                        100% {
                            opacity: 0;
                        }
                    }

                    .Persistent .calc_text_caret {
                        position: relative;
                        display: inline;
                    }
                    .Persistent .text_caret_mobile {
                        right: -2px;
                        position: absolute;
                        display: inline;
                        /* font-size: 21px; */
                        border-left: 1px solid black;
                        z-index: 10;
                        /* height: 35px; */
                        animation: blinker 1s linear infinite;
                        /* border-top: none; */
                        /* border-right: none; */
                    }

                    .Persistent :global(.ContentEditable) {
                        z-index: 0;
                        -webkit-user-select: text;
                        -moz-user-select: text;
                        -ms-user-select: text;
                        user-select: text;
                        line-height: 1.15;
                        background: #fff;
                        border-radius: 2px;
                        border: none;
                        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
                        z-index: 3;
                        margin: 0 auto;
                        outline: none;
                        cursor: text;
                    }
                    .Persistent :global(.ContentEditable.oneliner) {
                        white-space: pre-wrap;
                        max-height: 24px;
                        overflow: hidden;
                        height: 24px;
                        overflow: hidden;
                        margin-bottom: 20px;
                        font-size: 16px;
                        padding: 10px;
                    }
                    .Persistent :global(.ContentEditable.oneliner br) {
                        display: none;
                    }
                    .Persistent :global(.ContentEditable.oneliner *) {
                        display: inline;
                        white-space: nowrap;
                    }
                    :global(.len0) {
                        font-size: 24px;
                    }
                    :global(.len85) {
                        font-size: 20px;
                    }
                    :global(.len170) {
                        font-size: 18px;
                    }
                    .Persistent :global(.ContentEditable:hover) {
                        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
                    }
                `}
            </style>
            {!isIOSClient && (
                <div key='textbox-'>
                    {mediaQueries.isMobileMedia && (
                        <span>
                            <input className='ghostInput' onFocus={(e) => e.target.blur()} ref={ghostInput} />
                            <span
                                className='text_caret text_caret_mobile'
                                ref={caretRef}
                                style={{ display: 'block' }} // if mediaQueries.isMobileMedia is true
                            />
                            <span className='calc_text_caret' ref={calcCaretRef} />
                        </span>
                    )}

                    <ContentEditable
                        aria-label='Text Area'
                        className={`ContentEditable vkinput ${oneliner ? 'oneliner' : ''} ${
                            adaptiveFontSize ? fontSizeClass : ''
                        } ${className}`}
                        innerRef={innerRef}
                        html={TextboxState[dataSaveId] || ''} // innerHTML of the editable div
                        onChange={handleChange} // handle innerHTML change
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        disabled={mediaQueries.isMobileMedia && keyboardState.active} //not working on ssr, not hydrating... fixed with a hook above
                        onTouchEnd={() => mediaQueries.isMobileMedia && ghostInput.current.focus()}
                        placeholder={typeHereString}
                        onClick={handleClick}
                        // tagName='article' // Use a custom HTML tag (uses a div by default)
                        {...props}
                    />
                </div>
            )}
            {isIOSClient && (
                <div key='textbox-'>
                    <textarea
                        className={`ContentEditable vkinput ${oneliner ? 'oneliner' : ''} ${
                            adaptiveFontSize ? fontSizeClass : ''
                        } ${className}`}
                        value={TextboxState[dataSaveId] || ''}
                        ref={innerRef}
                        onInput={handleChange} // handle innerHTML change
                        onFocus={handleFocus}
                        onClick={handleTextAreaClickIos}
                        onBlur={handleTextAreaBlurIos}
                        readOnly={keyboardState.active ? true : ''}
                        placeholder={typeHereString}
                        // tagName='article' // Use a custom HTML tag (uses a div by default)
                        {...props}
                    />
                </div>
            )}
            {/* note: <Suspense> here made problems in concurrent loading */}
            {showEditBar && (
                <EditBar rtl={rtl} text={TextboxState[dataSaveId]} setText={setEditBarText} editableRef={innerRef} />
            )}
        </div>
    )
})

PersistentContentEditable.displayName = 'PersistentContentEditable'
export default PersistentContentEditable
