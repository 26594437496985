import React from 'react'
// import logo from './Loader/logo.svg'
// import { makeStyles } from '@material-ui/core/styles'
// import LinearProgress from '@material-ui/core/LinearProgress'

// import './Loader.scss'

// const useStyles = makeStyles({
//     root: {
//         flex-grow: 1;
//         position: absolute;
//         width: 100%;
//         textAlign: 'center'
//     }
// })

// loading component for suspence fallback
const Loader = ({ style }) => {
    // const classes = useStyles()
    return (
        <div
            className='lds-container'
            style={style ? style : undefined}
            // className={classes.root}
        >
            {/* <LinearProgress /> */}
            <div className='lds-ellipsis'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <style jsx>{`
                .lds-container {
                    flex-grow: 1;
                    position: absolute;
                    width: 100%;
                    height: 30px;
                    text-align: center;
                    z-index: 9999;
                    left: 0;
                }

                .lds-ellipsis {
                    display: inline-block;
                    position: relative;
                    width: 80px;
                    height: 80px;
                }
                .lds-ellipsis div {
                    position: absolute;
                    top: 33px;
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    background: #fff;
                    animation-timing-function: cubic-bezier(0, 1, 1, 0);
                }
                .lds-ellipsis div:nth-child(1) {
                    left: 8px;
                    animation: lds-ellipsis1 0.6s infinite;
                }
                .lds-ellipsis div:nth-child(2) {
                    left: 8px;
                    animation: lds-ellipsis2 0.6s infinite;
                }
                .lds-ellipsis div:nth-child(3) {
                    left: 32px;
                    animation: lds-ellipsis2 0.6s infinite;
                }
                .lds-ellipsis div:nth-child(4) {
                    left: 56px;
                    animation: lds-ellipsis3 0.6s infinite;
                }
                @keyframes lds-ellipsis1 {
                    0% {
                        transform: scale(0);
                    }
                    100% {
                        transform: scale(1);
                    }
                }
                @keyframes lds-ellipsis3 {
                    0% {
                        transform: scale(1);
                    }
                    100% {
                        transform: scale(0);
                    }
                }
                @keyframes lds-ellipsis2 {
                    0% {
                        transform: translate(0, 0);
                    }
                    100% {
                        transform: translate(24px, 0);
                    }
                }
            `}</style>
        </div>
    )
}

export default Loader
