import React from 'react'
import DropButtonMenu from '../../../Components/DropButtonMenu.js'

import SearchIcon from '@material-ui/icons/Search'
import ShareIcon from '@material-ui/icons/Share'

import TranslateIcon from '@material-ui/icons/Translate'

// const TranslateButton = ({ i18n }) => {
//     const [, GotoDispatch] = useGotoContext()
//     return (
//         <ActionButton
//             bgColor='#419c8b'
//             i18n={i18n}
//             startIcon={<TranslateIcon />}
//             onClick={useCallback(() => {
//                 GotoDispatch({
//                     type: 'go',
//                     to: 'translate'
//                 })
//             }, [GotoDispatch])}
//         >
//             {i18n.t('UI.(button)Translate')}
//         </ActionButton>
//     )
// }

const ActionBar = React.memo(({ i18n, kbLangKey }) => {
    return (
        <div className='action-menu' style={{ color: 'white' }}>
            <style jsx>{`
                .action-menu {
                    background: #b6bcc3;
                    border-radius: 14px 14px 0 0;
                    padding-bottom: 2px;
                    max-width: 720px;
                    margin: auto;
                }
                @media (max-width: 720px) {
                    .action-menu {
                        border-radius: 0;
                    }
                }
            `}</style>
            <DropButtonMenu
                text={i18n.t('UI.(button)Share')}
                bgColor='#9385cc'
                i18n={i18n}
                Icon={ShareIcon}
                eventKey='share'
                which={'share'}
                menuItemProps={{ kbLangKey }}
            />
            <DropButtonMenu
                text={i18n.t('UI.(button)Translate')}
                bgColor='#419c8b'
                i18n={i18n}
                Icon={TranslateIcon}
                eventKey='translate_text'
                which={'translate_text'}
                dropdown={false}
                menuItemProps={{ kbLangKey }}
            />

            {/* <TranslateButton i18n={i18n} /> */}
            <DropButtonMenu
                text={i18n.t('UI.(button)Search')}
                bgColor='#3480fc'
                i18n={i18n}
                Icon={SearchIcon}
                eventKey='search'
                which={'search'}
                menuItemProps={{ kbLangKey }}
            />
        </div>
    )
})

ActionBar.displayName = 'ActionBar'
export default ActionBar
