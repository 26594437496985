import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { green, red } from '@material-ui/core/colors'

import Switch from '@material-ui/core/Switch'
import { Tooltip } from '@material-ui/core'

const ColoredSwitch = withStyles({
    switchBase: {
        color: red[500],
        pointerEvents: 'all',
        '&$checked': {
            color: green[500]
        },
        '&$checked + $track': {
            backgroundColor: green[500]
        }
    },
    checked: {},
    track: {}
})(Switch)

const SwitchOnOff = React.memo(({ checked = true, onChange = () => true, tooltip = '' }) => {
    return (
        <Tooltip title={tooltip}>
            <FormControlLabel
                control={<ColoredSwitch checked={checked} onChange={onChange} value='checkedB' color='primary' />}
                label={checked ? 'ON' : 'OFF'}
            />
        </Tooltip>
    )
})

const SwitchRegular = React.memo(({ checked = false, onChange, label, tooltip = '' }) => {
    return (
        <Tooltip title={tooltip}>
            <FormControlLabel
                control={<Switch checked={checked} onChange={onChange} value='checkedG' color='primary' />}
                label={label}
            />
        </Tooltip>
    )
})

SwitchOnOff.displayName = 'SwitchOnOff'
SwitchRegular.displayName = 'SwitchRegular'

export { SwitchOnOff, SwitchRegular }
